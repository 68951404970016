import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './file-upload-zone.scss';
import Button from './button';
import { ReactComponent as Upload } from '../images/icons/Upload.svg';
import LoadingSpinner from './LoadingSpinner';

FileUploadZone.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onFileSelected: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isUploading: PropTypes.bool,
  uploadProgress: PropTypes.number,
};

FileUploadZone.defaultProps = {
  uploadProgress: 0,
};

export default function FileUploadZone({
  accept,
  onFileSelected,
  isUploading,
  //  uploadProgress,
  onCancel,
}) {
  const [droppedFileName, setDroppedFileName] = useState(null);

  return (
    <>
      <div
        className="file-upload-zone"
        style={{ display: isUploading ? 'block' : 'none' }}
      >
        <LoadingSpinner label="common.file-upload.loader" />
        <p>{droppedFileName}</p>
        <div>
          <Button buttonType="outlined" size="s" onClick={onCancel}>
            common.button.cancel
          </Button>
        </div>
      </div>
      <div style={{ display: isUploading ? 'none' : 'block' }}>
        <Dropzone
          accept={accept}
          multiple={false}
          onDropAccepted={files => {
            setDroppedFileName(files[0].name);
            onFileSelected(files[0]);
          }}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            open,
          }) => {
            const handleOpen = e => {
              e.preventDefault();
              e.stopPropagation();
              open();
            };
            const className = classNames('file-upload-zone', {
              'file-upload-zone--active': isDragActive,
              'file-upload-zone--rejected': isDragReject,
            });
            return (
              <div
                {...getRootProps({ onClick: evt => evt.preventDefault() })}
                className={className}
              >
                <input {...getInputProps()} />
                <Upload className="file-upload-zone__icon" />
                <div className="file-upload-zone__title">
                  <FormattedMessage id="common.file-upload.drag-n-drop" />
                </div>
                {!isDragReject && (
                  <p>
                    <FormattedMessage id="common.file-upload.your-files" />
                  </p>
                )}
                {isDragReject && (
                  <p>
                    <FormattedMessage id="common.file-upload.unsupported-file-type" />
                  </p>
                )}
                <Button onClick={handleOpen} size="s">
                  common.file-upload.browse
                </Button>
                <p>
                  <FormattedMessage id="common.file-upload.instruction" />
                </p>
              </div>
            );
          }}
        </Dropzone>
      </div>
    </>
  );
}
