/* eslint-disable prefer-const */
import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LoanForm from './LoanForm';
import PageHeader from '../../../components/page-header';
import useApiGet from '../../../utils/useApiGet';
import timeout from '../../../utils/timeout';
import api from '../../../utils/api';
import useApiGetRepeat from '../../../utils/useApiGetRepeat';
import InstrumentType, { InstrumentShortName } from '../utils/InstrumentType';

import { parseClients, parsePortfolios } from '../utils/parseRequestInfo';
import LoadingSpinner from '../../../components/LoadingSpinner';
import InterestRateDealForm from './InterestRateDealForm';
import PageError from '../../../components/PageError';

import { ERRORS_BY_STATUS_CODE } from '../../../constants';
import CurrentUserContext from '../../../CurrentUserContext';
import { CapFloorForm } from './CapFloorDealForm';

const DEAL_TYPE_URLS = {
  [InstrumentType.FIXED_RATE]: 'fixedrateloans',
  [InstrumentType.FLOATING_RATE]: 'floatingrateloans',
  [InstrumentType.INTEREST_RATE]: 'interestrateswaps',
  [InstrumentType.CAP_FLOOR]: 'capfloors',
};

const INITAL_DEAL_PARAMS_URLS = {
  [InstrumentType.FIXED_RATE]: 'fixedrateloanparameters',
  [InstrumentType.FLOATING_RATE]: 'floatingrateloanparameters',
  [InstrumentType.INTEREST_RATE]: 'interestrateswapparameters',
  [InstrumentType.CAP_FLOOR]: 'capfloorparameters',
};

const FORM_FOOTER_TITLE = 'create-deal.form-footer.title';
const FORM_FOOTER_DESCRIPTION = 'create-deal.form-footer.description';

const getBreadCrumbs = (instrumentType, clientId) => {
  if (!clientId) {
    return [
      {
        title: <FormattedMessage id="client-manager.page-title" />,
        path: '/clients',
      },
      {
        title: (
          <FormattedMessage
            id="create-deal.page-title"
            values={{
              type: (
                <FormattedMessage id={InstrumentShortName[instrumentType]} />
              ),
            }}
          />
        ),
        path: '/clients/create-deal',
      },
    ];
  }
  return [
    {
      title: <FormattedMessage id="client-manager.page-title" />,
      path: '/clients',
    },
    {
      title: clientId,
      path: `/clients/${clientId}`,
    },
    {
      title: (
        <FormattedMessage
          id="create-deal.page-title"
          values={{
            type: <FormattedMessage id={InstrumentShortName[instrumentType]} />,
          }}
        />
      ),
      path: `/clients/${clientId}/create-deal`,
    },
  ];
};

function ClientPortfolioPage() {
  const { instrumentType, clientId } = useParams();
  const [currentClientId, setCurrentClientId] = useState(clientId);
  const [addedPortfolios, setAddedPortfolios] = useState([]);
  const navigate = useNavigate();

  const {
    state: [clients, isClientsLoading, clientsError],
    setResult: setClients,
  } = useApiGetRepeat('/clientmanager');
  const {
    state: [portfolios, , portfolioError],
  } = useApiGetRepeat(`/portfoliomanager?client_id=${currentClientId}`);

  const parsedClients = parseClients(clients);
  const parsedPortfolios = parsePortfolios(portfolios);

  const { setUser } = useContext(CurrentUserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialParams, initialParamsLoading] = useApiGet(
    '/' + INITAL_DEAL_PARAMS_URLS[instrumentType],
  );

  const formInitialParameters = {
    ...initialParams,
    client_id: clientId || '',
  };

  const [formErrors, setFormErrors] = useState(null);

  const isLoading = initialParamsLoading || isClientsLoading;

  if (!Object.values(InstrumentType).includes(instrumentType)) {
    return <PageError />;
  }

  if (isLoading || isSubmitting) {
    return <LoadingSpinner />;
  }

  const error = portfolioError || clientsError;
  if (error) {
    const msg =
      ERRORS_BY_STATUS_CODE[error.response.status] || 'Unknown error.';
    setUser(null);
    return <PageError customMessage={msg} redirectToHome />;
  }

  async function onCreateDeal(values) {
    try {
      await api.post(`/${DEAL_TYPE_URLS[instrumentType]}`, values);
      await timeout(3000);
      setIsSubmitting(false);
      navigate(`/clients/${currentClientId}`);
    } catch (e) {
      // eslint-disable-next-line
      console.log('e', e);
      if (e.response && e.response.data) {
        parseValidationErrors(e.response.data.Errors);
      }
      setIsSubmitting(false);
    }
  }

  const parseValidationErrors = errorData => {
    const parsedErrors = {};
    errorData.forEach(apiError => {
      parsedErrors[apiError.Source] = apiError.Message;
    });
    setFormErrors(parsedErrors);
  };

  function addPortfolio(portfolio) {
    setAddedPortfolios([...addedPortfolios, portfolio]);
  }

  function addClient(client) {
    setClients([...parsedClients, client]);
  }

  const getFormTag = instrType => {
    if (InstrumentType.INTEREST_RATE === instrType) {
      return InterestRateDealForm;
    }
    return InstrumentType.CAP_FLOOR === instrType ? CapFloorForm : LoanForm;
  };

  const FormTag = getFormTag(instrumentType);

  return (
    <>
      <PageHeader breadcrumbs={getBreadCrumbs(instrumentType, clientId)} />
      {!isLoading && (
        <FormTag
          clients={parsedClients}
          instrumentType={instrumentType}
          onSubmit={onCreateDeal}
          portfolios={[...addedPortfolios, ...parsedPortfolios]}
          initialValues={formInitialParameters}
          footerTitle={FORM_FOOTER_TITLE}
          footerDescription={FORM_FOOTER_DESCRIPTION}
          onClientChange={newClientId => setCurrentClientId(newClientId)}
          onCreateClient={client => addClient(client)}
          onCreatePortfolio={portfolio => addPortfolio(portfolio)}
          formErrors={formErrors}
        />
      )}
    </>
  );
}

export default ClientPortfolioPage;
