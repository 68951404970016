import React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import muiTheme from './muiTheme';
import Routes from './Routes';
import { I18nProvider } from './I18nContext';
import { CurrentUserProvider } from './CurrentUserContext';
import setupHighcharts from './setupHighcharts';
import ErrorBoundary from './components/ErrorBoundary';

setupHighcharts();

function App() {
  return (
    <I18nProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <MuiThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CurrentUserProvider>
                <Routes />
              </CurrentUserProvider>
            </LocalizationProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </I18nProvider>
  );
}

export default App;
