import React from 'react';
import { withStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = () => ({
  formControl: {
    width: '100%',
  },
  select: {
    fontFamily: 'Vaud Medium',
    fontSize: '14px',
    fontWeight: '600',
  },
});

Select.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  value: PropTypes.string,
  error: PropTypes.object,
  margin: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
};

Select.defaultProps = {
  margin: 'normal',
  error: null,
  options: [],
};

function Select({
  id,
  value,
  onChange,
  name,
  options,
  label,
  classes,
  margin,
  required,
  ...rest
}) {
  return (
    <FormControl
      className={classes.formControl}
      margin={margin}
      required={required}
      variant="standard"
    >
      {label && (
        <InputLabel htmlFor={id}>
          <FormattedMessage id={label} />
        </InputLabel>
      )}
      <MuiSelect
        id={id}
        value={value}
        onChange={onChange}
        name={name}
        className={classes.select}
        aria-describedby="component-helper-text"
        {...rest}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label ? (
              <FormattedMessage id={option.label} />
            ) : (
              option.text
            )}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

/*
 <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Age</InputLabel>
      <MuiSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value="10"
        label="Age"
        onChange={onChange}
      >
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </MuiSelect>
    </FormControl>
    */

export default withStyles(styles)(Select);
