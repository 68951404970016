import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from './button';
import { ReactComponent as Error } from '../images/icons/Error.svg';

NotFoundPage.propTypes = {
  customMessage: PropTypes.string,
  redirectToHome: PropTypes.bool,
};

export default function NotFoundPage({
  customMessage = null,
  redirectToHome = false,
}) {
  return (
    <div className="center-both-ways">
      <div>
        <Error />
        <p>
          {customMessage || (
            <FormattedMessage id="common.page-error.error-message" />
          )}
        </p>
        {redirectToHome ? (
          <Button buttonType="outlined" size="s" to="/" uppercase>
            common.page-error.back-button
          </Button>
        ) : (
          <Button
            buttonType="outlined"
            size="s"
            onClick={() => window.location.reload()}
            uppercase
          >
            common.button.refresh
          </Button>
        )}
      </div>
    </div>
  );
}
