import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { getTranslations } from './translations/translations-utils';

export const I18nContext = React.createContext({
  locale: 'en',
  setLocale: () => {},
});

export const I18nProvider = ({ children }) => {
  const [locale, setLocale] = useState('en');
  const [messages, setMessages] = useState(getTranslations(locale));

  useEffect(() => {
    if (locale) {
      setMessages(getTranslations(locale));
    }
  }, [locale]);

  return (
    <I18nContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
};

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
