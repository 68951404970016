import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FileUploadZone from '../../../components/FileUploadZone';
import api from '../../../utils/api';
import { ReactComponent as SuccessIcon } from '../../../images/icons/Success.svg';
import { ReactComponent as ErrorIcon } from '../../../images/icons/Error.svg';

import './deal-upload.scss';
import Button from '../../../components/button';
import { MIME_TYPE_XLSX } from '../../../constants';

DealUpload.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

export default function DealUpload({ onFinish }) {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadResult, setUploadResult] = useState(null);
  const [cancelFunction, setCancelFunction] = useState(null);

  async function onUploadStart(file) {
    setUploading(true);
    try {
      const { response, cancel } = api.uploadFile('/importdeals', file, {
        onUploadProgress: setUploadProgress,
      });
      setCancelFunction(() => cancel);
      const result = await response;
      setUploadResult(result);
    } catch (e) {
      if (!api.wasRequestedCancelled(e)) {
        // eslint-disable-next-line
        console.error(e);
      }
    } finally {
      setUploading(false);
    }
  }

  function onUploadCancel() {
    cancelFunction();
    setUploading(false);
  }
  return (
    <div className="deal-upload-page">
      <h2 className="center-horizontally margin-unit-1">
        <FormattedMessage id="deal-upload.title" />
      </h2>
      {uploadResult && renderResult()}
      {!uploadResult && renderUploadZone()}
    </div>
  );

  function renderUploadZone() {
    return (
      <>
        <FileUploadZone
          accept={MIME_TYPE_XLSX}
          onFileSelected={onUploadStart}
          isUploading={uploading}
          onCancel={onUploadCancel}
          uploadProgress={uploadProgress}
        />
        {!uploading && (
          <>
            <p>
              <FormattedMessage id="deal-upload.upload-zone.download-templates" />
            </p>
            <p>
              <a href={process.env.PUBLIC_URL + '/import-deals.xlsx'}>
                import-deals.xlsx
              </a>
            </p>
          </>
        )}
      </>
    );
  }

  function renderResult() {
    const isSuccess = uploadResult.status === 'ok';
    const imported = uploadResult.importedCount;
    const total = uploadResult.totalCount;
    return (
      <>
        <p>
          {isSuccess ? (
            <SuccessIcon className="deal-upload-page__success-icon" />
          ) : (
            <ErrorIcon className="deal-upload-page__failure-icon" />
          )}
        </p>
        {isSuccess && (
          <>
            <p>
              <FormattedMessage id="deal-upload.result.successful" />
            </p>
            <p>
              <FormattedMessage
                id="deal-upload.result.nr-of-imports"
                values={{ imported }}
              />
            </p>
          </>
        )}
        {!isSuccess && (
          <>
            <p>
              <FormattedMessage id="deal-upload.result.failure" />
            </p>
            <p>
              <FormattedMessage
                id="deal-upload.result.successful-imports"
                values={{ imported, total }}
              />
            </p>
            <p>
              <FormattedMessage
                id="deal-upload.result.see-errors"
                values={{
                  link: (
                    <a href="api/errorfile">
                      <FormattedMessage id="deal-upload.result.excel-link" />
                    </a>
                  ),
                }}
              />
            </p>
          </>
        )}
        <div style={{ textAlign: 'center' }}>
          <Button onClick={onFinish} buttonType="outlined" size="m">
            common.button.ok
          </Button>
        </div>
      </>
    );
  }
}
