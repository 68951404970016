import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import NumberFormat from 'react-number-format';
import Table from '../../../components/table';
import DateDisplay from '../../../components/DateDisplay';
import './deal-schedule-list.scss';
import collectArrayFieldsToObjects from '../utils/collectArrayFieldsToObjects';
import ActionBar from '../../../layout/ActionBar';
import PercentDisplay from '../../../components/PercentDisplay';

DealScheduleList.propTypes = {
  schedule: PropTypes.object,
  loading: PropTypes.bool,
};

function DealScheduleList({ schedule, loading }) {
  const payLegSchedule = schedule && schedule.pay_leg_schedule;
  const receiveLegSchedule = schedule && schedule.receive_leg_schedule;
  const payLegPayments = useMemo(
    () => collectArrayFieldsToObjects(payLegSchedule, payLegMapping),
    [payLegSchedule],
  );
  const receiveLegPayments = useMemo(
    () => collectArrayFieldsToObjects(receiveLegSchedule, receiveLegMapping),
    [receiveLegSchedule],
  );

  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={7} md={7}>
        <ActionBar
          title={<FormattedMessage id="client-loan.table.receive-leg.title" />}
        />
        <Table
          showPlaceholder
          config={receiveLegTable}
          data={receiveLegPayments}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} sm={5} md={5} style={{ paddingLeft: 20 }}>
        <ActionBar
          title={<FormattedMessage id="client-loan.table.pay-leg.title" />}
        />
        <Table
          showPlaceholder
          config={payLegTable}
          data={payLegPayments}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

const payLegMapping = {
  interest_cashflows: 'cashFlowInterest',
  coupon_rates: 'couponRate',
  payment_dates: 'paymentDate',
};

const receiveLegMapping = {
  payment_dates: 'paymentDate',
  nominal_balances: 'nominalBalance',
  interest_cashflows: 'cashFlowInterest',
  coupon_rates: 'couponRate',
};

const receiveLegTable = {
  idField: 'paymentDate',
  cols: [
    {
      title: 'client-loan.table.receive-leg.payment-date',
      value: row => <DateDisplay value={row.paymentDate} />,
      align: 'left',
    },
    {
      title: 'client-loan.table.receive-leg.nominal',
      numeric: true,
      value: row => renderNumber(row.nominalBalance),
    },
    {
      title: 'client-loan.table.receive-leg.cash-flow-interest',
      numeric: true,
      value: row => renderNumber(row.cashFlowInterest),
    },
    {
      title: 'client-loan.table.receive-leg.coupon-rate',
      value: row => <PercentDisplay value={row.couponRate} decimalScale={4} />,
    },
  ],
};

const payLegTable = {
  idField: 'paymentDate',
  cols: [
    {
      title: 'client-loan.table.pay-leg.cash-flow-interest',
      numeric: true,
      value: row => renderNumber(row.cashFlowInterest),
    },
    {
      title: 'client-loan.table.pay-leg.coupon-rate',
      value: row => <PercentDisplay value={row.couponRate} />,
    },
  ],
};

function renderNumber(value) {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator=" "
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

export default DealScheduleList;
