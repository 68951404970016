export function required(value) {
  let error;
  if (!value) {
    error = 'Required';
  }
  return error;
}

export function range(minValue, maxValue) {
  return value => {
    let error;
    if (value < minValue) {
      error = `Value must be higher then ${minValue}`;
    } else if (value > maxValue) {
      error = `Value must be lower then ${maxValue}`;
    }
    return error;
  };
}

export function maxLength(length) {
  return value => {
    let error;

    if (value.length > length) {
      error = `Length cannot exceed ${length}`;
    }
    return error;
  };
}

export function composeValidators(...validators) {
  return value => {
    let error;
    validators.some(validator => {
      error = validator(value);
      return !!error;
    });
    return error;
  };
}
