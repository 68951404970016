import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ContentBox from '../../layout/ContentBox';
import ActionBar from '../../layout/ActionBar';

KPIContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasTabs: PropTypes.bool,
  noMarginTop: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default function KPIContainer({
  hasTabs,
  children,
  noMarginTop,
  title,
}) {
  return (
    <ContentBox hasTabs={hasTabs} noMarginTop={noMarginTop} noPaddingBottom>
      <ActionBar title={title ? <FormattedMessage id={title} /> : ''} />
      <div className="kpis">
        {React.Children.toArray(children).map((child, index) => (
          <React.Fragment key={index}>{child}</React.Fragment>
        ))}
      </div>
    </ContentBox>
  );
}
