import React from 'react';
import PropTypes from 'prop-types';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import parseServerDateTime from '../utils/parseServerDateTime';
import { DEFAULT_DATE_FORMAT } from '../constants';

DateDisplay.propTypes = {
  customFormat: PropTypes.string,
  isoDateTime: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

DateDisplay.defaultProps = {
  isoDateTime: false,
};

function DateDisplay({ customFormat, isoDateTime, value }) {
  if (!value) {
    return null;
  }
  let date = null;
  if (value instanceof Date) {
    date = value;
  } else {
    date = isoDateTime
      ? parseServerDateTime(value)
      : parse(value, 'yyyy-MM-dd', new Date());
  }

  return format(new Date(date.getTime()), customFormat || DEFAULT_DATE_FORMAT);
}
export default React.memo(DateDisplay);
