import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

KPIItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default function KPIItem({ value, label, suffix }) {
  return (
    <div className="kpis__item">
      <span className="kpis__divider" />
      <div className="kpis__amount">
        {value}
        {suffix && <sup>{suffix}</sup>}
      </div>
      <div className="kpis__type">
        <FormattedMessage id={label} />
      </div>
    </div>
  );
}
