import axios from 'axios';
// test git
const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

class Api {
  constructor(basePath) {
    this.basePath = basePath;
    this.cancelToken = axios.CancelToken;
  }

  async get(path) {
    const response = await axios.get(this.basePath + path);
    return response.data;
  }

  async post(path, body) {
    const response = await axios.post(
      this.basePath + path,
      body,
      defaultConfig,
    );
    return response.data;
  }

  async postwithbearertoken(path, body, bearerjwt) {
    const response = await axios.post(this.basePath + path, body, {
      headers: {
        Authorization: 'Bearer ' + bearerjwt,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  }

  async put(path, body) {
    const response = await axios.put(this.basePath + path, body, defaultConfig);
    return response.data;
  }

  async delete(path) {
    const response = await axios.delete(this.basePath + path);
    return response.data;
  }

  uploadFile(path, file, options = { onUploadProgress: () => null }) {
    // eslint-disable-next-line
    const data = new FormData();
    data.append('file', file);
    const tokenSource = this.cancelToken.source();
    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        options.onUploadProgress(percentCompleted);
      },
      cancelToken: tokenSource.token,
    };
    return {
      response: axios
        .post(this.basePath + path, data, config)
        .then(res => res.data),
      cancel: () => tokenSource.cancel('Operation canceled by the user.'),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  wasRequestedCancelled(error) {
    return axios.isCancel(error);
  }
}

export default new Api('/api');
