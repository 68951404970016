import get from 'lodash.get';
import useStateToggle from '../../../utils/useStateToggle';

export default function useBindableInputs(context, path1, path2) {
  const [cloneEnabled, toggleCloneEnabled] = useStateToggle(false);
  const { setFieldValue, handleChange, values } = context;

  return {
    togglePath1: () => {
      toggleCloneEnabled();
      if (!cloneEnabled) {
        setFieldValue(path2, get(values, path1));
      }
    },
    togglePath2: () => {
      toggleCloneEnabled();
      if (!cloneEnabled) {
        setFieldValue(path1, get(values, path2));
      }
    },
    onChange1: value => {
      handleChange(value);
      if (cloneEnabled) {
        setFieldValue(path2, value.target.value);
      }
    },
    onChange2: value => {
      handleChange(value);
      if (cloneEnabled) {
        setFieldValue(path1, value.target.value);
      }
    },
    enabled: cloneEnabled,
  };
}
