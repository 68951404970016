import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import CurrentUserContext from '../../CurrentUserContext';
import PageHeader from '../../components/page-header';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  DEFAULT_DATE_TIME_FORMAT,
  ERRORS_BY_STATUS_CODE,
} from '../../constants';
import PageError from '../../components/PageError';
import './threshold-monitoring.scss';
import TresholdList from './TresholdList';
import { SectionTabs } from '../../layout/SectionTabs';
import useApiGetRepeat from '../../utils/useApiGetRepeat';
import { ReactComponent as TooltipIcon } from '../../images/icons/Tooltip.svg';
import DateDisplay from '../../components/DateDisplay';
import KPIBox from '../../components/kpis/KPIBox';
import { nFormatter } from '../../utils/formatters';
import colors from '../../colors';

const tabs = [
  { key: 'own', content: <FormattedMessage id="section-tab.own-clients" /> },
  {
    key: 'regional',
    content: <FormattedMessage id="section-tab.regional-clients" />,
  },
];

const tabUrls = {
  own: '/thresholdreports',
  regional: '/thresholdreports?account_group=region',
};

function ThresholdMonitoringPage() {
  const { setUser } = useContext(CurrentUserContext);
  const [activeTabKey, setActiveTabKey] = useState('own');

  const {
    state: [reports, areReportsLoading, reportsError],
  } = useApiGetRepeat(tabUrls[activeTabKey]);

  if (areReportsLoading) {
    return <LoadingSpinner />;
  }

  if (reportsError) {
    const msg =
      ERRORS_BY_STATUS_CODE[reportsError.response.status] || 'Unknown error.';
    setUser(null);
    return <PageError customMessage={msg} redirectToHome />;
  }

  const handleTabChange = key => {
    setActiveTabKey(key);
  };

  const breadcrumbTail = missingDeals => (
    <span className="text-regular">
      <FormattedMessage
        id="threshold-monitoring.page-title.tail"
        values={{
          notUpdatedDeals: (
            <span className="text-red">
              <FormattedMessage
                id="threshold-monitoring.page-title.tail.not-updated-deals"
                values={{ nrOfDeals: missingDeals && missingDeals.length }}
              />
            </span>
          ),
        }}
      />
    </span>
  );

  const getBreadCrumbTitle = (missingDeals, lastModified) => (
    <>
      <FormattedMessage id="threshold-monitoring.page-title" />
      {missingDeals && missingDeals.length > 0 && breadcrumbTail(missingDeals)}
      <Tooltip
        title={
          <FormattedMessage
            id="common.last-updated"
            values={{
              modified: lastModified ? (
                <DateDisplay
                  customFormat={DEFAULT_DATE_TIME_FORMAT}
                  value={new Date(lastModified)}
                />
              ) : (
                ''
              ),
            }}
          />
        }
        enterTouchDelay={0}
        aria-label="Last modified"
      >
        <div style={{ display: 'inline-block', marginLeft: 4 }}>
          <TooltipIcon />
        </div>
      </Tooltip>
    </>
  );

  const getKPIItems = function () {
    const {
      npv_of_breached_deals,
      nr_of_clients,
      nr_of_deals,
      nr_of_deals_below_minus_10_breach,
      nr_of_deals_below_minus_20_breach,
    } = reports.total;

    const formattedBranches = (nrOfDealsBelowBreach, nrOfDeals) => (
      <>
        <span style={{ color: colors.RED }}>{nrOfDealsBelowBreach}</span>/
        {nrOfDeals}
      </>
    );

    const [npvValue, npvSuffix] = nFormatter(npv_of_breached_deals, 2);

    return [
      {
        value: formattedBranches(
          nr_of_deals_below_minus_10_breach,
          nr_of_deals,
        ),
        suffix: (
          <span className="breaches-suffix">
            <FormattedMessage
              id="threshold-monitoring.metrics.breaches.suffix"
              values={{ nrOfBreaches: nr_of_deals_below_minus_20_breach }}
            />
          </span>
        ),
        label: 'threshold-monitoring.metrics.breaches',
      },
      {
        value: npvValue,
        suffix: npvSuffix,
        label: 'threshold-monitoring.metrics.breached-npv',
      },
      {
        value: nr_of_clients,
        label: 'threshold-monitoring.metrics.clients',
      },
    ];
  };

  const kpiItems = getKPIItems();

  return (
    <div className="threshold-monitoring">
      <PageHeader
        breadcrumbs={[
          {
            title: getBreadCrumbTitle(
              reports.missing_deals,
              reports.modified_utc,
            ),
            path: '/threshold-monitoring',
          },
        ]}
      />
      <SectionTabs
        activeTabKey={activeTabKey}
        handleTabChange={handleTabChange}
        tabs={tabs}
      />
      <KPIBox items={kpiItems} hasTabs />
      <TresholdList
        deals={reports.deals}
        isRegional={activeTabKey === 'regional'}
      />
    </div>
  );
}

ThresholdMonitoringPage.propTypes = {};

export default ThresholdMonitoringPage;
