import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import {
  DatePicker,
  Select,
  SelectWithCreate,
  TextField,
} from '../../../components/forms/formik';
import { DEFAULT_DATE_FORMAT } from '../../../constants';
import InstrumentType from '../utils/InstrumentType';
import {
  AMORTIZATION_FREQUENCIES,
  BUSINESS_DAY_CONVENTIONS,
  CURRENCIES,
  DAY_COUNT_CONVENTIONS,
  HOLIDAY_CALENDARS,
  PAYMENT_FREQUENCIES,
  ASSET_LIABILITIES,
} from '../utils/lookup-values';
import {
  composeValidators,
  maxLength,
  range,
  required,
} from '../../../components/forms/formik/validators';
import FormikPercentageInput from '../../../components/forms/formik/input-types/FormikPercentageInput';
import FormikMoneyAmountInput from '../../../components/forms/formik/input-types/FormikMoneyAmountInput';
import AdvancedParametersToggle from '../../../components/forms/AdvancedParametersToggle';
import validateOnSubmitOnly from '../../../utils/validateOnSubmitOnly';
import FormControlFooter from '../../../components/forms/FormControlFooter';
import ActionBar from '../../../layout/ActionBar';

const NO_AMORTIZATION = 'no_amortization';

const FormComponent = ({
  classes,
  clients,
  dirty,
  footerDescription,
  footerTitle,
  formErrors,
  handleReset,
  instrumentType,
  onCreateClient,
  onCreatePortfolio,
  onClientChange,
  portfolios,
  setErrors,
  setFieldValue,
  submitForm,
  values,
}) => {
  const [advancedDealParamsVisible, setAdvancedDealParams] = useState(false);

  const disableAmortization =
    values.amortization &&
    values.amortization.amortization_frequency === NO_AMORTIZATION;

  const amortizationScheduleAmountsExist =
    values.amortization &&
    values.amortization.amortization_schedule &&
    values.amortization.amortization_schedule.amounts !== null;

  useEffect(() => {
    setErrors(formErrors);
  }, [formErrors, setErrors]);
  return (
    <Form className={classes.container}>
      {dirty && (
        <FormControlFooter
          title={footerTitle}
          description={footerDescription}
          onCancel={handleReset}
          onSave={submitForm}
        />
      )}
      <div
        className={
          advancedDealParamsVisible ? 'parameters-box-open' : 'parameters-box'
        }
      >
        <ActionBar title={<FormattedMessage id="loan-form.title" />}>
          <small>
            <FormattedMessage id="common.form.mandatory" />
          </small>
        </ActionBar>
        <Grid container direction="column">
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="loan-deal-name"
                margin="normal"
                name="deal_name"
                label="loan-form.field.deal-name"
                required
                validate={composeValidators(required, maxLength(255))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <SelectWithCreate
                required
                validate={required}
                id="loan-portfolio-name"
                label="loan-form.field.portfolio-name"
                name="portfolio_name"
                margin="normal"
                options={portfolios.map(val => ({
                  value: val,
                  text: val,
                }))}
                createItemPlaceHolder="Enter new portfolio's name"
                onCreateItem={onCreatePortfolio}
                autoOk
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <SelectWithCreate
                required
                validate={required}
                id="loan-client-id"
                label="loan-form.field.client-id"
                name="client_id"
                margin="normal"
                onChange={event => {
                  const newValue = event.target.value;
                  setFieldValue('client_id', newValue);
                  onClientChange(newValue);
                }}
                options={clients.map(val => ({
                  value: val,
                  text: val,
                }))}
                createItemPlaceHolder="Enter new client's name"
                onCreateItem={onCreateClient}
                autoOk
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="loan-counterparty-name"
                label="loan-form.field.counterparty-name"
                name="counterparty_name"
                margin="normal"
                validate={composeValidators(maxLength(255))}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="loan-nominal-currency"
                label="loan-form.field.nominal"
                name="nominal"
                margin="normal"
                inputComponent={FormikMoneyAmountInput}
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      id="loan-nominal-currency-select"
                      margin="none"
                      name="currency"
                      options={CURRENCIES}
                      variant="standard"
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                margin="normal"
                name="trade_date"
                data-testid="trade_date"
                label="loan-form.field.trade-date"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Select
                id="loan-asset-liability"
                data-testid="loan-asset-liability"
                label="loan-form.field.direction"
                name="asset_liability"
                margin="normal"
                options={ASSET_LIABILITIES}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm={3} md={3}>
              <Select
                id="loan-payment-frequency"
                label="loan-form.field.payment-frequency"
                name="payment_frequency"
                margin="normal"
                options={PAYMENT_FREQUENCIES}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              {instrumentType === InstrumentType.FIXED_RATE && (
                <TextField
                  id="loan-fixed-rate"
                  margin="normal"
                  name="fixed_rate"
                  label="loan-form.field.fixed-rate"
                  inputComponent={FormikPercentageInput}
                  inputProps={{
                    decimalScale: 4,
                  }}
                />
              )}
              {instrumentType === InstrumentType.FLOATING_RATE && (
                <TextField
                  id="loan-margin"
                  margin="normal"
                  name="margin"
                  label="loan-form.field.margin"
                  inputComponent={FormikPercentageInput}
                  inputProps={{
                    decimalScale: 4,
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                margin="normal"
                name="value_date"
                data-testid="value_date"
                label="loan-form.field.value-date"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                margin="normal"
                name="maturity_date"
                data-testid="maturity_date"
                label="loan-form.field.maturity-date"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AdvancedParametersToggle
              onClick={() => {
                setAdvancedDealParams(!advancedDealParamsVisible);
              }}
              enabled={advancedDealParamsVisible}
            />
          </Grid>
        </Grid>
      </div>
      {advancedDealParamsVisible && (
        <div className="advanced-parameters-box">
          {!amortizationScheduleAmountsExist && (
            <>
              <div className="actions-bar__section_title">
                <FormattedMessage id="loan-form.section.amortization" />
              </div>
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} sm={3} md={3}>
                  <Select
                    id="loan-amortization-frequency"
                    data-testid="loan-amortization-frequency"
                    label="loan-form.field.amort-freq"
                    name="amortization.amortization_frequency"
                    margin="normal"
                    options={AMORTIZATION_FREQUENCIES}
                    onChange={(e, value) => {
                      const selectedFrequency = value.props.value;
                      setFieldValue(
                        'amortization.amortization_frequency',
                        selectedFrequency,
                      );
                      if (selectedFrequency === 'no_amortization') {
                        setFieldValue('amortization.amortization_amount', null);
                        setFieldValue('amortization.amortization_percent', 0);
                        setFieldValue(
                          'amortization.amortization_start_date',
                          undefined,
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    id="loan-amortization-amount"
                    label="loan-form.field.amort-amount"
                    name="amortization.amortization_amount"
                    inputComponent={FormikMoneyAmountInput}
                    margin="normal"
                    disabled={disableAmortization}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    id="loan-amortization-amount-percent"
                    label="loan-form.field.amort-amount-percent"
                    name="amortization.amortization_percent"
                    margin="normal"
                    disabled={disableAmortization}
                    inputComponent={FormikPercentageInput}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <DatePicker
                    id="loan-amortization-start-date"
                    label="loan-form.field.amort-start-date"
                    name="amortization.amortization_start_date"
                    margin="normal"
                    disabled={disableAmortization}
                    format={DEFAULT_DATE_FORMAT}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <div className="actions-bar__section_title">
            <FormattedMessage id="loan-form.section.conventions" />
          </div>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <Select
                id="loan-business-day-convention"
                label="loan-form.field.business-day-convention"
                name="business_day_convention"
                margin="normal"
                options={BUSINESS_DAY_CONVENTIONS}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Select
                id="loan-day-count-conventions"
                label="loan-form.field.day-count-convention"
                name="day_count"
                margin="normal"
                options={DAY_COUNT_CONVENTIONS}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Select
                id="loan-holiday-calendar"
                label="loan-form.field.holiday-calendar"
                name="holiday_calendar"
                margin="normal"
                options={HOLIDAY_CALENDARS}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                id="loan-first-payment-date"
                label="loan-form.field.first-payment-date"
                name="first_payment_date"
                margin="normal"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="loan-payment-day"
                label="loan-form.field.field.payment-day"
                name="payment_day"
                margin="normal"
                validate={composeValidators(range(0, 31))}
              />
            </Grid>
            {instrumentType === InstrumentType.FLOATING_RATE && (
              <>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    id="loan-floor-reference-rate"
                    label="loan-form.field.floor-reference-rate"
                    name="floor_reference_rate"
                    margin="normal"
                    inputComponent={FormikPercentageInput}
                    inputProps={{
                      decimalScale: 4,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    id="loan-floor-coupon-rate"
                    label="loan-form.field.floor-coupon-rate"
                    name="floor_coupon_rate"
                    margin="normal"
                    inputComponent={FormikPercentageInput}
                    inputProps={{
                      decimalScale: 4,
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
      )}
    </Form>
  );
};

FormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  dirty: PropTypes.bool,
  footerDescription: PropTypes.string,
  footerTitle: PropTypes.string,
  formErrors: PropTypes.object,
  handleReset: PropTypes.func.isRequired,
  instrumentType: PropTypes.oneOf(Object.values(InstrumentType)).isRequired,
  onCreateClient: PropTypes.func.isRequired,
  onCreatePortfolio: PropTypes.func.isRequired,
  onClientChange: PropTypes.func.isRequired,
  portfolios: PropTypes.array,
  setErrors: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.object,
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

LoanForm.propTypes = {
  classes: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCreateClient: PropTypes.func.isRequired,
  onCreatePortfolio: PropTypes.func.isRequired,
  onClientChange: PropTypes.func.isRequired,
  instrumentType: PropTypes.oneOf(Object.values(InstrumentType)).isRequired,
  initialValues: PropTypes.object,
  portfolios: PropTypes.array,
  footerTitle: PropTypes.string,
  footerDescription: PropTypes.string,
  formErrors: PropTypes.object,
};

export function LoanForm({
  classes,
  clients,
  initialValues,
  portfolios,
  onSubmit,
  instrumentType,
  footerTitle,
  footerDescription,
  onCreateClient,
  onCreatePortfolio,
  onClientChange,
  formErrors,
}) {
  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={validateOnSubmitOnly(onSubmit, validateOnSubmit)}
      validateOnBlur={false}
    >
      {({
        dirty,
        handleReset,
        values,
        setErrors,
        setFieldValue,
        submitForm,
      }) => (
        <FormComponent
          classes={classes}
          clients={clients}
          dirty={dirty}
          footerDescription={footerDescription}
          footerTitle={footerTitle}
          formErrors={formErrors}
          handleReset={handleReset}
          instrumentType={instrumentType}
          onCreateClient={onCreateClient}
          onCreatePortfolio={onCreatePortfolio}
          onClientChange={onClientChange}
          portfolios={portfolios}
          setErrors={setErrors}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
          values={values}
        />
      )}
    </Formik>
  );
}

function validateForm(values) {
  const errors = {};
  if (!values.deal_name) {
    errors.deal_name = 'Required';
  }
  return errors;
}

function validateOnSubmit(values) {
  const errors = {};
  const maturityDate =
    values.maturity_date instanceof Date
      ? values.maturity_date
      : new Date(values.maturity_date);

  const valueDate =
    values.value_date instanceof Date
      ? values.value_date
      : new Date(values.value_date);

  if (valueDate.getTime() > maturityDate.getTime()) {
    errors.maturity_date = 'Must be after Value date';
  }
  return errors;
}

export default withStyles(styles)(LoanForm);
