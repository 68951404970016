import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage } from 'react-intl';
import Popover from '@mui/material/Popover';
import InstrumentType, { InstrumentName } from './utils/InstrumentType';
import Button from '../../components/button';

export const AddDealButton = ({ handleInstrumentRedirect, openModal }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function renderCreateMenuItem(instrumentType) {
    return (
      <MenuItem onClick={() => handleInstrumentRedirect(instrumentType)}>
        <FormattedMessage id={InstrumentName[instrumentType]} />
      </MenuItem>
    );
  }

  return (
    <>
      <Button size="s" onClick={handleClick}>
        common.button.add
      </Button>
      <Popover
        id="simple-popper"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {renderCreateMenuItem(InstrumentType.FIXED_RATE)}
        {renderCreateMenuItem(InstrumentType.FLOATING_RATE)}
        {renderCreateMenuItem(InstrumentType.INTEREST_RATE)}
        {renderCreateMenuItem(InstrumentType.CAP_FLOOR)}
        <MenuItem
          onClick={() => {
            openModal();
            handleClose();
          }}
        >
          <FormattedMessage id="client-details.add-option.import-deal-data" />
        </MenuItem>
      </Popover>
    </>
  );
};

AddDealButton.propTypes = {
  handleInstrumentRedirect: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};
