import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const FormikPercentageInput = React.forwardRef((props, ref) => {
  const { onChange, name, value, decimalScale, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      value={value * 100}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.floatValue / 100,
          },
        });
      }}
      isNumericString
      decimalSeparator="."
      decimalScale={decimalScale}
      fixedDecimalScale
      suffix="%"
    />
  );
});

FormikPercentageInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  decimalScale: PropTypes.number,
};

FormikPercentageInput.defaultProps = {
  value: 0,
  decimalScale: 2,
};

export default FormikPercentageInput;
