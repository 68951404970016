import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableCell from '@mui/material/TableCell';
import MatTableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import TablePropTypes from './TablePropTypes';

const useStyles = makeStyles({
  row: {
    height: 48,
  },
  clickableRow: {
    cursor: 'pointer',
  },
});

TableRow.propTypes = {
  config: TablePropTypes.config.isRequired,
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number,
  onRowClick: PropTypes.func,
};

function TableRow({ config, row, onRowClick, rowIndex }) {
  const classes = useStyles();

  function handleRowClick(id, item, event) {
    if (
      typeof event.target.className === 'string' &&
      event.target.className.match(/menu__item/)
    ) {
      return;
    }
    if (onRowClick) {
      onRowClick(id, item, event);
    }
  }

  const { cols, idField } = config;
  return (
    <MatTableRow
      className={classNames(classes.row, {
        [classes.clickableRow]: !!onRowClick,
      })}
      onClick={event => handleRowClick(row[idField], row, event)}
    >
      {cols.map(col => {
        const colKey = getColKey(col);
        const style = {};
        if (col.width) {
          style.width = col.width;
        }
        const displayValue = getColumnDisplayValue(col, row, rowIndex);
        return (
          <TableCell align={col.align || 'right'} key={colKey} style={style}>
            <span
              className={`generic-table__col-value ${
                col.numeric ? 'no-wrap' : ''
              } ${colValuePaddingClass(col.align)}`}
            >
              {displayValue}
            </span>
          </TableCell>
        );
      })}
    </MatTableRow>
  );
}

function getColumnDisplayValue(col, row, rowIndex) {
  return typeof col.value === 'function'
    ? col.value(row, rowIndex)
    : row[col.value];
}

function getColKey(col) {
  return col.key ? col.key : col.title;
}

const colValuePaddingClass = align => {
  if (align === 'right' || !align) {
    return 'generic-table__col-right-align-padding';
  }
  return align === 'left' ? 'generic-table__col-left-align-padding' : '';
};

export default React.memo(TableRow);
