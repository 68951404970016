import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const FormikMoneyAmountInput = React.forwardRef((props, ref) => {
  const { onChange, name, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator=" "
      decimalSeparator="."
      fixedDecimalScale
      decimalScale={2}
    />
  );
});

FormikMoneyAmountInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormikMoneyAmountInput;
