import React from 'react';
import { isSameDay, parseISO, format } from 'date-fns';
import PropTypes from 'prop-types';
import DatePicker from '../DatePicker';

FormikDatePicker.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string,
};

export default function FormikDatePicker({ form, name, ...props }) {
  return (
    <DatePicker
      name={name}
      {...props}
      onChange={date => {
        const currentValue = form.values[name];
        if (!date) {
          form.setFieldValue(name, null, true);
        }
        try {
          if (date && !isSameDay(parseISO(currentValue), date)) {
            form.setFieldValue(
              name,
              format(new Date(date), 'yyyy-MM-dd'),
              true,
            );
          }
        } catch (ex) {
          form.setFieldValue(name, '', true);
        }
      }}
    />
  );
}
