import '../../../styles/form.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, withFormik } from 'formik';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import {
  DatePicker,
  Select,
  TextField,
  SelectWithCreate,
} from '../../../components/forms/formik/index';
import {
  composeValidators,
  maxLength,
  required,
} from '../../../components/forms/formik/validators';
import {
  CURRENCIES,
  HOLIDAY_CALENDARS,
  LEG_TYPES,
  PAYMENT_FREQUENCIES,
  LegType,
} from '../utils/lookup-values';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_MIN_TRADE_DATE,
} from '../../../constants';
import FormikPercentageInput from '../../../components/forms/formik/input-types/FormikPercentageInput';
import FormikMoneyAmountInput from '../../../components/forms/formik/input-types/FormikMoneyAmountInput';
import ActionBar from '../../../layout/ActionBar';
import DoubleCheckbox from '../../../components/forms/DoubleCheckbox';
import useBindableInputs from '../utils/useBindableInputs';
import AdvancedParametersToggle from '../../../components/forms/AdvancedParametersToggle';
import validateOnSubmitOnly from '../../../utils/validateOnSubmitOnly';
import FormControlFooter from '../../../components/forms/FormControlFooter';
import { LegAmortizationInputs } from './components/AmortizationSections';
import { LegConventionInputs } from './components/ConventionSections';

const minDate = new Date(DEFAULT_MIN_TRADE_DATE);

const legTypeValidation = legType1 => legType2 => {
  let error;
  if (legType1 === legType2) {
    error = 'The types cannot be identical.';
  }
  return error;
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

InterestRateDealForm.propTypes = {
  classes: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCreateClient: PropTypes.func.isRequired,
  onCreatePortfolio: PropTypes.func.isRequired,
  onClientChange: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  portfolios: PropTypes.array,
  footerTitle: PropTypes.string,
  footerDescription: PropTypes.string,
  formErrors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
};

function InterestRateDealForm({
  classes,
  clients,
  portfolios,
  onCreateClient,
  onCreatePortfolio,
  onClientChange,
  formErrors,
  setErrors,
  ...formikContext
}) {
  const [advancedDealParamsVisible, setAdvancedDealParams] = useState(false);
  useEffect(() => {
    setErrors(formErrors);
  }, [formErrors, setErrors]);

  const {
    dirty,
    handleReset,
    values,
    setFieldValue,
    submitForm,
    footerTitle,
    footerDescription,
  } = formikContext;

  useEffect(() => {
    if (!portfolios.includes(values.portfolio_name)) {
      setFieldValue('portfolio_name', '');
    }
  }, [portfolios, setFieldValue, values.portfolio_name]);

  const paymentFrequencyBindableInputs = useBindableInputs(
    formikContext,
    'receive_leg_parameters.payment_frequency',
    'pay_leg_parameters.payment_frequency',
  );

  // Leg Amortization Bound Values

  const amortizationFrequencyBindableInputs = useBindableInputs(
    formikContext,
    'receive_leg_parameters.amortization.amortization_frequency',
    'pay_leg_parameters.amortization.amortization_frequency',
  );

  const amortizationAmountBindableInputs = useBindableInputs(
    formikContext,
    'receive_leg_parameters.amortization.amortization_amount',
    'pay_leg_parameters.amortization.amortization_amount',
  );

  const amortizationPercentBindableInputs = useBindableInputs(
    formikContext,
    'receive_leg_parameters.amortization.amortization_percent',
    'pay_leg_parameters.amortization.amortization_percent',
  );

  const amortizationStartDateBindableInputs = useBindableInputs(
    formikContext,
    'receive_leg_parameters.amortization.amortization_start_date',
    'pay_leg_parameters.amortization.amortization_start_date',
  );

  // Leg Conventions bound values

  const businessDayConventionBindableInputs = useBindableInputs(
    formikContext,
    'receive_leg_parameters.business_day_convention',
    'pay_leg_parameters.business_day_convention',
  );

  const dayCountBindableInputs = useBindableInputs(
    formikContext,
    'receive_leg_parameters.day_count',
    'pay_leg_parameters.day_count',
  );

  const firstPaymentDateBindableInputs = useBindableInputs(
    formikContext,
    'receive_leg_parameters.first_payment_date',
    'pay_leg_parameters.first_payment_date',
  );

  const paymentDayBindableInputs = useBindableInputs(
    formikContext,
    'receive_leg_parameters.payment_day',
    'pay_leg_parameters.payment_day',
  );

  const receiveLegAmortizationDisabled =
    values.receive_leg_parameters.amortization.amortization_frequency ===
    'no_amortization';
  const payLegAmortizationDisabled =
    values.pay_leg_parameters.amortization.amortization_frequency ===
    'no_amortization';

  const receiveLegAmortizationScheduleAmountsExist =
    values.receive_leg_parameters.amortization &&
    values.receive_leg_parameters.amortization.amortization_schedule &&
    values.receive_leg_parameters.amortization.amortization_schedule.amounts !==
      null;

  const payLegAmortizationScheduleAmountsExist =
    values.pay_leg_parameters.amortization &&
    values.pay_leg_parameters.amortization.amortization_schedule &&
    values.pay_leg_parameters.amortization.amortization_schedule.amounts !==
      null;

  return (
    <Form className={classes.container}>
      {dirty && (
        <FormControlFooter
          title={footerTitle}
          description={footerDescription}
          onCancel={handleReset}
          onSave={submitForm}
        />
      )}
      <div
        className={
          advancedDealParamsVisible ? 'parameters-box-open' : 'parameters-box'
        }
      >
        <ActionBar title={<FormattedMessage id="interest-rate-form.title" />} />
        <Grid container direction="column">
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="loan-deal-name"
                margin="normal"
                name="deal_name"
                label="interest-rate-form.field.deal-name"
                required
                validate={composeValidators(required, maxLength(255))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <SelectWithCreate
                id="loan-portfolio-name"
                label="interest-rate-form.field.portfolio-name"
                name="portfolio_name"
                margin="normal"
                options={portfolios.map(val => ({
                  value: val,
                  text: val,
                }))}
                required
                validate={required}
                createItemPlaceHolder="Enter new portfolio's name"
                onCreateItem={onCreatePortfolio}
                autoOk
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={6} md={6}>
              <SelectWithCreate
                required
                validate={required}
                id="loan-client-id"
                label="interest-rate-form.field.client-id"
                name="client_id"
                margin="normal"
                onChange={event => {
                  const newValue = event.target.value;
                  setFieldValue('client_id', newValue);
                  onClientChange(newValue);
                }}
                options={clients.map(val => ({
                  value: val,
                  text: val,
                }))}
                createItemPlaceHolder="Enter new client's name"
                onCreateItem={onCreateClient}
                autoOk
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="loan-counterparty-name"
                label="interest-rate-form.field.counterparty-name"
                name="counterparty_name"
                margin="normal"
                validate={composeValidators(maxLength(255))}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="loan-nominal-currency"
                label="interest-rate-form.field.nominal"
                name="nominal"
                margin="normal"
                inputComponent={FormikMoneyAmountInput}
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      id="loan-nominal-currency-select"
                      margin="none"
                      name="currency"
                      options={CURRENCIES}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                margin="normal"
                name="trade_date"
                minDate={minDate}
                label="interest-rate-form.field.trade-date"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Select
                id="loan-holiday-calendar"
                label="interest-rate-form.field.holiday-calendar"
                name="holiday_calendar"
                margin="normal"
                options={HOLIDAY_CALENDARS}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                margin="normal"
                name="value_date"
                data-testid="value_date"
                label="interest-rate-form.field.value-date"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                margin="normal"
                name="maturity_date"
                data-testid="maturity_date"
                label="interest-rate-form.field.maturity-date"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="actions-bar__section_title">
                <FormattedMessage id="interest-rate-form.section.receive-leg" />
              </div>
              <Grid item xs={12} sm={12} md={12}>
                <Select
                  id="receive_leg_parameters-leg-type"
                  data-testid="receive_leg_parameters-leg-type"
                  margin="normal"
                  name="receive_leg_parameters.leg_type"
                  label="interest-rate-form.field.leg-type"
                  options={LEG_TYPES}
                  validate={composeValidators(
                    legTypeValidation(values.pay_leg_parameters.leg_type),
                  )}
                  onChange={(e, value) => {
                    setFieldValue('receive_leg_parameters.leg_type', value.key);
                    setFieldValue('receive_leg_parameters.margin', 0);
                    setFieldValue('receive_leg_parameters.fixed_rate', 0);

                    setFieldValue(
                      'pay_leg_parameters.leg_type',
                      value.key === 'fixed' ? 'floating' : 'fixed',
                    );
                    setFieldValue('pay_leg_parameters.margin', 0);
                    setFieldValue('pay_leg_parameters.fixed_rate', 0);
                  }}
                />
              </Grid>
              <Grid container direction="row" spacing={24}>
                <Grid item xs={12} sm={8} md={8}>
                  <div className={classes.row}>
                    <Select
                      id="receive_leg_parameters-payment-frequency"
                      margin="normal"
                      name="receive_leg_parameters.payment_frequency"
                      label="interest-rate-form.field.payment-frequency"
                      onChange={paymentFrequencyBindableInputs.onChange1}
                      options={PAYMENT_FREQUENCIES}
                    />
                    <DoubleCheckbox
                      checked={paymentFrequencyBindableInputs.enabled}
                      onChange={paymentFrequencyBindableInputs.togglePath1}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  {values.receive_leg_parameters &&
                    values.receive_leg_parameters.leg_type ===
                      LegType.FIXED && (
                      <TextField
                        id="receive-leg-fixed-rate"
                        margin="normal"
                        name="receive_leg_parameters.fixed_rate"
                        label="interest-rate-form.field.fixed-rate"
                        inputProps={{
                          decimalScale: 4,
                        }}
                        inputComponent={FormikPercentageInput}
                      />
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="actions-bar__section_title">
                <FormattedMessage id="interest-rate-form.section.pay-leg" />
              </div>
              <Grid item xs={12} sm={12} md={12}>
                <Select
                  id="pay_leg_parameters-leg-type"
                  margin="normal"
                  name="pay_leg_parameters.leg_type"
                  label="interest-rate-form.field.leg-type"
                  options={LEG_TYPES}
                  validate={composeValidators(
                    legTypeValidation(values.receive_leg_parameters.leg_type),
                  )}
                  onChange={(e, value) => {
                    setFieldValue('pay_leg_parameters.leg_type', value.key);
                    setFieldValue('pay_leg_parameters.margin', 0);
                    setFieldValue('pay_leg_parameters.fixed_rate', 0);

                    setFieldValue(
                      'receive_leg_parameters.leg_type',
                      value.key === 'fixed' ? 'floating' : 'fixed',
                    );
                    setFieldValue('receive_leg_parameters.margin', 0);
                    setFieldValue('receive_leg_parameters.fixed_rate', 0);
                  }}
                />
              </Grid>
              <Grid container direction="row" spacing={24}>
                <Grid item xs={12} sm={8} md={8}>
                  <div className={classes.row}>
                    <Select
                      id="pay_leg_parameters-payment-frequency"
                      margin="normal"
                      name="pay_leg_parameters.payment_frequency"
                      label="interest-rate-form.field.payment-frequency"
                      onChange={paymentFrequencyBindableInputs.onChange2}
                      options={PAYMENT_FREQUENCIES}
                    />
                    <DoubleCheckbox
                      checked={paymentFrequencyBindableInputs.enabled}
                      onChange={paymentFrequencyBindableInputs.togglePath2}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  {values.pay_leg_parameters.leg_type === LegType.FIXED && (
                    <TextField
                      id="pay-leg-fixed-rate"
                      margin="normal"
                      name="pay_leg_parameters.fixed_rate"
                      label="interest-rate-form.field.fixed-rate"
                      inputProps={{
                        decimalScale: 4,
                      }}
                      inputComponent={FormikPercentageInput}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AdvancedParametersToggle
              onClick={() => {
                setAdvancedDealParams(!advancedDealParamsVisible);
              }}
              enabled={advancedDealParamsVisible}
            />
          </Grid>
        </Grid>
      </div>
      {advancedDealParamsVisible && (
        <div className="advanced-parameters-box">
          {(!receiveLegAmortizationScheduleAmountsExist ||
            !payLegAmortizationScheduleAmountsExist) && (
            <Grid container direction="row" spacing={24}>
              <Grid item xs={12} sm={6} md={6}>
                {!receiveLegAmortizationScheduleAmountsExist && (
                  <>
                    <div className="actions-bar__section_title">
                      <FormattedMessage id="interest-rate-form.section.receive-leg-amort" />
                    </div>
                    <LegAmortizationInputs
                      amountBindings={amortizationAmountBindableInputs}
                      classes={classes}
                      disabled={receiveLegAmortizationDisabled}
                      frequencyBindings={amortizationFrequencyBindableInputs}
                      legType="receive"
                      percentBindings={amortizationPercentBindableInputs}
                      startDateBindings={amortizationStartDateBindableInputs}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {!payLegAmortizationScheduleAmountsExist && (
                  <>
                    <div className="actions-bar__section_title">
                      <FormattedMessage id="interest-rate-form.section.pay-leg-amort" />
                    </div>
                    <LegAmortizationInputs
                      amountBindings={amortizationAmountBindableInputs}
                      classes={classes}
                      disabled={payLegAmortizationDisabled}
                      frequencyBindings={amortizationFrequencyBindableInputs}
                      legType="pay"
                      percentBindings={amortizationPercentBindableInputs}
                      startDateBindings={amortizationStartDateBindableInputs}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          )}
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="actions-bar__section_title">
                <FormattedMessage id="interest-rate-form.section.receive-leg-conventions" />
              </div>
              <LegConventionInputs
                businessDayBindings={businessDayConventionBindableInputs}
                classes={classes}
                dayCountBindings={dayCountBindableInputs}
                firstPaymentDateBindings={firstPaymentDateBindableInputs}
                legType="receive"
                paymentDayBindings={paymentDayBindableInputs}
              />
              {values.receive_leg_parameters.leg_type === LegType.FLOATING && (
                <FloorReferenceAndCouponRates legType="receive" />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="actions-bar__section_title">
                <FormattedMessage id="interest-rate-form.section.pay-leg-conventions" />
              </div>
              <LegConventionInputs
                businessDayBindings={businessDayConventionBindableInputs}
                classes={classes}
                dayCountBindings={dayCountBindableInputs}
                firstPaymentDateBindings={firstPaymentDateBindableInputs}
                legType="pay"
                paymentDayBindings={paymentDayBindableInputs}
              />
              {values.pay_leg_parameters.leg_type === LegType.FLOATING && (
                <FloorReferenceAndCouponRates legType="pay" />
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </Form>
  );
}

const FloorReferenceAndCouponRates = ({ legType }) => (
  <>
    <Grid item xs={12} sm={12} md={12}>
      <TextField
        id={`${legType}-leg-floor-reference-rate`}
        margin="normal"
        name={`${legType}_leg_parameters.floor_reference_rate`}
        label="interest-rate-form.field.floor-reference-rate"
        inputProps={{
          decimalScale: 4,
        }}
        inputComponent={FormikPercentageInput}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <TextField
        id={`${legType}-leg-floor-coupon-rate`}
        margin="normal"
        name={`${legType}_leg_parameters.floor_coupon_rate`}
        label="interest-rate-form.field.floor-coupon-rate"
        inputProps={{
          decimalScale: 4,
        }}
        inputComponent={FormikPercentageInput}
      />
    </Grid>
  </>
);

FloorReferenceAndCouponRates.propTypes = {
  legType: PropTypes.string.isRequired,
};

function validateForm(values) {
  const errors = {};
  if (!values.deal_name) {
    errors.deal_name = 'Required';
  }

  return errors;
}

function validateOnSubmit(values) {
  const errors = {};
  const maturityDate =
    values.maturity_date instanceof Date
      ? values.maturity_date
      : new Date(values.maturity_date);

  const valueDate =
    values.value_date instanceof Date
      ? values.value_date
      : new Date(values.value_date);

  if (valueDate.getTime() > maturityDate.getTime()) {
    errors.maturity_date = 'Must be after Value date';
  }

  return errors;
}

const clearInput = values => {
  const payLegParameters = { ...values.pay_leg_parameters };
  const receiveLegParameters = { ...values.receive_leg_parameters };
  if (values.receive_leg_parameters.leg_type === LegType.FLOATING) {
    delete receiveLegParameters.fixed_rate;
    delete payLegParameters.margin;
    delete payLegParameters.floor_reference_rate;
    delete payLegParameters.floor_coupon_rate;
  } else if (values.receive_leg_parameters.leg_type === LegType.FIXED) {
    delete payLegParameters.fixed_rate;
    delete receiveLegParameters.margin;
    delete receiveLegParameters.floor_reference_rate;
    delete receiveLegParameters.floor_coupon_rate;
  }
  return {
    ...values,
    pay_leg_parameters: payLegParameters,
    receive_leg_parameters: receiveLegParameters,
  };
};

const encahncedForm = withFormik({
  mapPropsToValues: ({ initialValues }) => initialValues,
  validate: validateForm,
  handleSubmit: (values, { props: { onSubmit }, ...rest }) => {
    validateOnSubmitOnly(onSubmit, validateOnSubmit)(clearInput(values), rest);
  },
})(InterestRateDealForm);

export default withStyles(styles)(encahncedForm);
