import React from 'react';
import PropTypes from 'prop-types';

import './section-tabs.scss';

const Tab = ({ active, content, onClick }) => {
  const keyPress = event => {
    if (event.which === 13 || event.keyCode === 13) {
      onClick();
    }
  };

  return (
    <div
      className={`section-tabs__tab ${
        active ? 'section-tabs__tab__active' : ''
      }`}
      onClick={onClick}
      onKeyPress={keyPress}
      role="button"
      tabIndex={0}
    >
      {content}
    </div>
  );
};

Tab.propTypes = {
  active: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const SectionTabs = ({ activeTabKey, handleTabChange, tabs }) => (
  <div className="section-tabs">
    {tabs.map(tab => (
      <Tab
        active={activeTabKey === tab.key}
        content={tab.content}
        key={`section-tab-${tab.key}`}
        onClick={() => handleTabChange(tab.key)}
      />
    ))}
  </div>
);

SectionTabs.propTypes = {
  activeTabKey: PropTypes.string,
  handleTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
