import enNotes from './release-notes_en.json';
import deNotes from './release-notes_de.json';

export const getReleaseNotes = locale => {
  if (locale === 'en') {
    return enNotes;
  }
  if (locale === 'de') {
    return deNotes;
  }
  return enNotes;
};
