import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link } from 'react-router-dom';
import Table from '../../components/table';
import ContentBox from '../../layout/ContentBox';
import ActionBar from '../../layout/ActionBar';
import { numberWithSpaces } from '../../utils/formatters';
import colors from '../../colors';
import DateDisplay from '../../components/DateDisplay';
import useSorting from '../../utils/useSorting';

function TresholdList({ deals = [], isRegional }) {
  const config = {
    idField: 'deal_id',
    cols: [
      {
        key: 'deal_name',
        title: 'threshold-monitoring.table.overview.deal-name',
        value: row => (
          <Link
            to={`/clients/${row.client_id}/deals/${row.deal_id}${
              isRegional ? '?account_group=region' : ''
            }`}
          >
            {row.deal_name}
          </Link>
        ),
        sortable: true,
        sortableColumn: 'deal_name',
        align: 'left',
      },
      {
        key: 'client_id',
        title: 'threshold-monitoring.table.overview.client-name',
        value: row => (
          <Link
            to={`/clients/${row.client_id}${
              isRegional ? '?account_group=region' : ''
            }`}
          >
            {row.client_id}
          </Link>
        ),
        sortable: true,
        sortableColumn: 'client_id',
        align: 'left',
      },
      {
        key: 'threshold_statistic',
        title: 'threshold-monitoring.table.overview.statistics',
        value: row => {
          const percentage = (row.threshold_statistic * 100).toFixed(2);
          if (percentage < -10) {
            return (
              <span style={{ color: colors.RED }}>{percentage + '%'}</span>
            );
          }
          return percentage + '%';
        },
        sortable: true,
        sortableColumn: 'threshold_statistic',
        align: 'right',
      },
      {
        key: 'current_npv',
        title: 'threshold-monitoring.table.overview.npv',
        numeric: true,
        value: row => numberWithSpaces(row.current_npv.toFixed(2)),
        sortable: true,
        sortableColumn: 'current_npv',
        align: 'right',
      },
      {
        key: 'current_nominal',
        title: 'threshold-monitoring.table.overview.notional',
        numeric: true,
        // eslint-disable-next-line
        value: ({ current_nominal }) =>
          // eslint-disable-next-line
          numberWithSpaces(current_nominal.toFixed(2)),
        sortable: true,
        sortableColumn: 'current_nominal',
        align: 'right',
      },
      {
        key: 'npv_at_trade_date',
        title: 'threshold-monitoring.table.overview.npv-at-trade-date',
        numeric: true,
        value: row => numberWithSpaces(row.npv_at_trade_date.toFixed(2)),
        sortable: true,
        sortableColumn: 'npv_at_trade_date',
        align: 'right',
      },
      {
        key: 'trade_date',
        title: 'threshold-monitoring.table.overview.trade-date',
        // eslint-disable-next-line
        value: ({ trade_date }) => (
          // eslint-disable-next-line camelcase
          <DateDisplay value={trade_date} isoDateTime />
        ),
        sortable: true,
        sortableColumn: 'trade_date',
        align: 'right',
      },
    ],
  };

  const [orderedList, orderBy, order, onRequestSort] = useSorting(
    deals,
    'threshold_statistic',
    config.cols,
    false,
  );

  return (
    <ContentBox>
      <ActionBar
        title={
          <>
            <span>
              <FormattedMessage id="threshold-monitoring.table.overview.title" />
            </span>
            {/*
            <IconButton
              aria-label="More"
              aria-owns="some menu"
              aria-haspopup="true"
              onClick={() => {}}
            >
              <MoreHorizIcon />
            </IconButton>
        */}
          </>
        }
        spreadWide
      />
      <Table
        showPlaceholder
        config={config}
        data={orderedList}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
      />
    </ContentBox>
  );
}

export default TresholdList;

TresholdList.propTypes = {
  deals: PropTypes.array,
  isRegional: PropTypes.bool,
};

TresholdList.defaultProps = {
  deals: [],
};
