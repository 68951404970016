import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionBar from '../../layout/ActionBar';
import ContentBox from '../../layout/ContentBox';
import colors from '../../colors';

const chartLabels = {
  interest: {
    title: 'chart.interest.title',
    subtitle: 'chart.interest.subtitle',
  },
  'interest-with-nominal': {
    title: 'chart.interest-sensitivity-nominal.title',
    subtitle: 'chart.interest-sensitivity-nominal.subtitle',
  },
};

function getChartOptions(type) {
  switch (type) {
    case 'interest':
      return getInterestChartOptions();
    case 'interest-with-nominal':
      return getInterestWithNominalChartOptions();
    default:
      return {};
  }
}

function getInterestChartOptions() {
  return {
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    tooltip: {
      valueDecimals: 2,
    },
    xAxis: {
      offset: -175,
      tickLength: 1,
      lineColor: colors.LIGHT_GRAY_2,
      labels: {
        style: {
          color: colors.LIGHT_GRAY_2,
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          color: colors.LIGHT_GRAY_2,
        },
      },
    },
    legend: { enabled: false },
  };
}

function getInterestWithNominalChartOptions() {
  return {
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    tooltip: {
      valueDecimals: 2,
    },
    xAxis: {
      offset: -155,
      tickLength: 0,
      lineColor: 'transparent',
      labels: {
        style: {
          color: colors.LIGHT_GRAY_2,
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          color: colors.LIGHT_GRAY_2,
        },
      },
    },
  };
}

export default function InterestSensitivityByScenarioChart({ type, data }) {
  const intl = useIntl();

  const chartOptions = getChartOptions(type);
  const chartData = getChartData(chartOptions);

  function getChartData(chartOptions) {
    switch (type) {
      case 'interest':
        return getInterestChartData(chartOptions);
      case 'interest-with-nominal':
        return getInterestWithNominalChartData(chartOptions);
      default:
        return {};
    }
  }

  function getInterestChartData(chartOptions) {
    const orderedLabels = [
      '-2%',
      '-1.5%',
      '-1%',
      '-0.5%',
      '0%',
      '0.5%',
      '1%',
      '1.5%',
      '2%',
    ];

    return {
      xAxis: {
        ...chartOptions.xAxis,
        categories: orderedLabels,
      },
      series: [
        {
          color: colors.ORANGE,
          name: '',
          data: data.total.map(entry => entry.value),
        },
      ],
    };
  }

  function getInterestWithNominalChartData(chartOptions) {
    const orderedLabels = [
      '-2%',
      '-1.5%',
      '-1%',
      '-0.5%',
      '0%',
      '0.5%',
      '1%',
      '1.5%',
      '2%',
    ];
    const orderedKeys = [
      'rates_down_200bp',
      'rates_down_150bp',
      'rates_down_100bp',
      'rates_down_50bp',
      'market',
      'rates_up_50bp',
      'rates_up_100bp',
      'rates_up_150bp',
      'rates_up_200bp',
    ];

    const chartData = {
      xAxis: {
        ...chartOptions.xAxis,
        categories: orderedLabels,
      },
      series: [
        {
          name: intl.formatMessage({ id: 'chart.legend.master' }),
          data: orderedKeys.map(key => data.current.total[key]),
        },
      ],
    };
    if (data.chartType === 'masterWithStrategy') {
      chartData.series.push({
        name: intl.formatMessage({ id: 'chart.legend.with-strategy' }),
        data: orderedKeys.map(key => data.strategy.total[key]),
      });
    }
    return chartData;
  }

  return (
    <ContentBox>
      <ActionBar
        title={
          <>
            <span>
              <FormattedMessage id={chartLabels[type].title} />
            </span>
            {/*
            <IconButton
              aria-label="More"
              aria-owns="some menu"
              aria-haspopup="true"
              onClick={() => {}}
            >
              <MoreHorizIcon />
            </IconButton>
        */}
          </>
        }
        subtitle={<FormattedMessage id={chartLabels[type].subtitle} />}
        spreadWide
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={{ ...chartOptions, ...chartData }}
      />
    </ContentBox>
  );
}

InterestSensitivityByScenarioChart.propTypes = {
  type: PropTypes.oneOf(['interest', 'interest-with-nominal']).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.shape({
      total: PropTypes.array,
    }),
    PropTypes.shape({
      current: PropTypes.shape({
        total: PropTypes.shape(),
      }),
      strategy: PropTypes.shape({
        total: PropTypes.shape(),
      }),
      chartType: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

InterestSensitivityByScenarioChart.defaultProps = {
  type: 'interest',
  data: {
    total: [],
  },
};
