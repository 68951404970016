import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import useApiGet from '../../utils/useApiGet';
import LoadingSpinner from '../../components/LoadingSpinner';
import PageError from '../../components/PageError';
import {
  DEFAULT_DATE_TIME_FORMAT,
  ERRORS_BY_STATUS_CODE,
} from '../../constants';
import CurrentUserContext from '../../CurrentUserContext';
import PageHeader from '../../components/page-header';
import Nominal from './Nominal';
import BasicPointSensitivityChart from '../../components/charts/BasicPointSensitivityChart';
import InterestSensitivityByScenarioChart from '../../components/charts/InterestSensitivityByScenarioChart';
import './dashboard.scss';
import DateDisplay from '../../components/DateDisplay';
import { ReactComponent as TooltipIcon } from '../../images/icons/Tooltip.svg';
import PieChart from '../../components/charts/PieChart';
import KPIBox from '../../components/kpis/KPIBox';
import PercentDisplay from '../../components/PercentDisplay';
import { nFormatter } from '../../utils/formatters';

function DashboardPage() {
  const { setUser } = useContext(CurrentUserContext);
  const [reports, areReportsLoading, reportsError] =
    useApiGet('/RegulatoryReports');

  if (areReportsLoading) {
    return <LoadingSpinner />;
  }

  if (reportsError) {
    const msg =
      ERRORS_BY_STATUS_CODE[reportsError.response.status] || 'Unknown error.';
    setUser(null);
    return <PageError customMessage={msg} redirectToHome />;
  }

  const {
    aggregate_statistics,
    nominal_by_instrument_type,
    remaining_nominals,
    par_rate_sensitivity_by_tenor_buckets,
    npv_difference_by_deal_and_scenario,
  } = reports;

  const breadcrumbTail = missingDeals => (
    <span className="text-regular">
      <FormattedMessage
        id="dashboard.page-title.breadcrumb"
        values={{
          notUpdatedDeals: (
            <span className="text-red">
              <FormattedMessage
                id="dashboard.page-title.not-updated-deals"
                values={{ nrOfDeals: missingDeals && missingDeals.length }}
              />
            </span>
          ),
        }}
      />
    </span>
  );

  const getBreadCrumbTitle = (missingDeals, lastModified) => (
    <>
      <FormattedMessage id="dashboard.page-title" />
      {missingDeals && missingDeals.length > 0 && breadcrumbTail(missingDeals)}
      <Tooltip
        title={
          <FormattedMessage
            id="common.last-updated"
            values={{
              modified: lastModified ? (
                <DateDisplay
                  customFormat={DEFAULT_DATE_TIME_FORMAT}
                  value={new Date(lastModified)}
                />
              ) : (
                ''
              ),
            }}
          />
        }
        enterTouchDelay={0}
        aria-label="Last modified"
      >
        <div style={{ display: 'inline-block', marginLeft: 4 }}>
          <TooltipIcon />
        </div>
      </Tooltip>
    </>
  );

  const getKPIItems = function () {
    const [dealsValue, dealsSuffix] = nFormatter(
      aggregate_statistics.total.nominal_balance,
      2,
    );
    const [npvValue, npvSuffix] = nFormatter(aggregate_statistics.total.npv, 2);
    const [parRateSensitivityValue, parRateSensitivitySuffix] = nFormatter(
      aggregate_statistics.total.par_rate_sensitivity,
      2,
    );

    return [
      {
        value: aggregate_statistics.total.nr_of_deals,
        label: 'dashboard.metrics.deals',
      },
      {
        value: dealsValue,
        suffix: dealsSuffix,
        label: 'dashboard.metrics.nominal',
      },
      {
        value: npvValue,
        suffix: npvSuffix,
        label: 'dashboard.metrics.npv',
      },
      {
        value: (
          <PercentDisplay
            decimalScale={2}
            hideSuffix
            value={aggregate_statistics.total.npv_in_percent}
          />
        ),
        suffix: '%',
        label: 'dashboard.metrics.npv-percent',
      },
      {
        value: parRateSensitivityValue,
        suffix: parRateSensitivitySuffix,
        label: 'dashboard.metrics.par-rate-sensitivity',
      },
      {
        value: aggregate_statistics.total.nr_of_clients,
        label: 'dashboard.metrics.clients',
      },
    ];
  };

  const kpiItems = getKPIItems();

  return (
    <div className="risk-dashboard">
      <PageHeader
        breadcrumbs={[
          {
            title: getBreadCrumbTitle(
              reports.missing_deals,
              reports.modified_utc,
            ),
            path: '/dashboard',
          },
        ]}
      />
      <KPIBox title="dashboard.metrics.title" items={kpiItems} />
      <PieChart
        data={nominal_by_instrument_type}
        title="chart.pie.nominal-by-instrument-type"
      />
      <Nominal data={remaining_nominals} />
      <BasicPointSensitivityChart
        data={par_rate_sensitivity_by_tenor_buckets}
      />
      <InterestSensitivityByScenarioChart
        type="interest"
        data={npv_difference_by_deal_and_scenario}
      />
    </div>
  );
}

export default DashboardPage;
