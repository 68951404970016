import React from 'react';
import PropTypes from 'prop-types';

import MuiCheckbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';

import { ReactComponent as CheckboxCheckedIcon } from '../../images/icons/Double-Check-Checked.svg';
import { ReactComponent as CheckboxEmptyIcon } from '../../images/icons/Double-Check.svg';

import colors from '../../colors';

const useStyles = makeStyles(() => ({
  checkbox: {
    color: colors.WHITE,
    padding: 10,
    marginTop: 15,
  },
}));

DoubleCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function DoubleCheckbox({ checked, onChange, ...props }) {
  const classes = useStyles();

  return (
    <MuiCheckbox
      color="primary"
      icon={<CheckboxEmptyIcon style={{ fontSize: 30 }} />}
      checkedIcon={<CheckboxCheckedIcon style={{ fontSize: 30 }} />}
      onChange={onChange}
      checked={checked}
      className={classes.checkbox}
      {...props}
    />
  );
}
