import React from 'react';
import PropTypes from 'prop-types';
import useLocalStorage from './utils/useLocalStorage';

const CurrentUserContext = React.createContext({
  user: null,
  setUser: () => '',
});

CurrentUserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CurrentUserContext;

export function CurrentUserProvider({ children }) {
  const [user, setUser] = useLocalStorage('user');
  return (
    <CurrentUserContext.Provider value={{ user, setUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
}
