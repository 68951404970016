import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import './table.scss';
import TableHeader from './TableHeader';
import TablePropTypes from './TablePropTypes';
import TableRow from './TableRow';
import LoadingSpinner from '../LoadingSpinner';

class Table extends Component {
  constructor() {
    super();

    this.renderTable = () => {
      const {
        config,
        data,
        onRequestSort,
        order,
        orderBy,
        onRowClick,
        onAsyncActionClick,
        RowComponent,
      } = this.props;
      const { idField = 'id' } = config;

      return (
        <MaterialTable padding="none">
          <TableHeader
            config={config}
            onRequestSort={onRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {data.map((row, index) => (
              <RowComponent
                key={row[idField]}
                row={row}
                rowIndex={index}
                config={config}
                onRowClick={onRowClick}
                onAsyncActionClick={onAsyncActionClick}
              />
            ))}
          </TableBody>
        </MaterialTable>
      );
    };

    this.renderPlaceholder = () => {
      const { placeholder } = this.props;
      return <div className="generic-table__placeholder">{placeholder}</div>;
    };
  }

  render() {
    const { loading, data } = this.props;
    return (
      <div className="generic-table">
        {data && data.length > 0 && this.renderTable()}
        {loading && (
          <div className="generic-table__loading">
            <LoadingSpinner />
          </div>
        )}
      </div>
    );
  }
}

Table.propTypes = {
  config: TablePropTypes.config,
  data: PropTypes.array,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  onRequestSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRowClick: PropTypes.func,
  onAsyncActionClick: PropTypes.func,
  RowComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

Table.defaultProps = {
  order: 'asc',
  RowComponent: TableRow,
};

export default Table;
