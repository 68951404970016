import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Warning } from '../../images/icons/Warning.svg';
import Button from '../../components/button';

export default function LoginError() {
  const navigate = useNavigate();

  const handleButtonClick = e => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <div className="login-page__error">
      <Warning />
      <p>
        <FormattedMessage id="login.error.unexpected" />
        <br />
        <FormattedMessage id="login.error.try-again" />
      </p>
      <Button size="m" type="button" uppercase onClick={handleButtonClick}>
        common.button.back_to_login
      </Button>
    </div>
  );
}
