import React from 'react';
import PropTypes from 'prop-types';
import './kpis.scss';
import KPIItem from './KPIItem';
import KPIContainer from './KPIContainer';

KPIBox.propTypes = {
  hasTabs: PropTypes.bool,
  noMarginTop: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
      label: PropTypes.string.isRequired,
      suffix: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
    }).isRequired,
  ),
};

export default function KPIBox({ hasTabs, noMarginTop, title, items }) {
  if (items.length <= 0) {
    throw new Error('KPIBox expects at least one item');
  }

  const renderItems = function () {
    return items.map(item => {
      if (item.value === undefined) {
        console.warn(`KPI for label "${item.label}" is undefined`);
      }
      return (
        <KPIItem
          key={item.label}
          value={item.value || 0}
          label={item.label}
          suffix={item.suffix}
        />
      );
    });
  };

  return (
    <KPIContainer hasTabs={hasTabs} noMarginTop={noMarginTop} title={title}>
      {renderItems()}
    </KPIContainer>
  );
}
