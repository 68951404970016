import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import Table from '../../../components/table';
import DateDisplay from '../../../components/DateDisplay';
import InstrumentType from '../utils/InstrumentType';
import './deal-schedule-list.scss';
import collectArrayFieldsToObjects from '../utils/collectArrayFieldsToObjects';
import PercentDisplay from '../../../components/PercentDisplay';

DealScheduleList.propTypes = {
  schedule: PropTypes.object,
  instrumentType: PropTypes.oneOf(Object.values(InstrumentType)).isRequired,
  loading: PropTypes.bool,
};

function DealScheduleList({ schedule, instrumentType, loading }) {
  const payments = useMemo(
    () => collectArrayFieldsToObjects(schedule, arrayMapping),
    [schedule],
  );
  const config = useMemo(
    () => getConfigByType(instrumentType),
    [instrumentType],
  );
  return (
    <Table showPlaceholder config={config} data={payments} loading={loading} />
  );
}

function getConfigByType(instrumentType) {
  const cols = [
    {
      title: 'client-loan.table.deal-schedule.payment-date',
      value: row => <DateDisplay value={row.paymentDate} />,
      align: 'left',
    },
    {
      title: 'client-loan.table.deal-schedule.nominal-amount',
      numeric: true,
      value: row => renderNumber(row.nominalBalance),
    },
    {
      title: 'client-loan.table.deal-schedule.nominal-amort',
      numeric: true,
      value: row => renderNumber(row.nominalAmortization),
    },
    {
      title: 'client-loan.table.deal-schedule.cash-flow-interest',
      numeric: true,
      value: row => renderNumber(row.cashFlowInterest),
    },
    {
      title: 'client-loan.table.deal-schedule.coupon-rate',
      value: row => <PercentDisplay value={row.couponRate} />,
    },
  ];

  if (instrumentType === InstrumentType.CAP_FLOOR) {
    cols.push({
      title: 'client-loan.table.deal-schedule.strike',
      value: row => <PercentDisplay value={row.strike} />,
    });
  }

  if (
    [InstrumentType.CAP_FLOOR, InstrumentType.FLOATING_RATE].includes(
      instrumentType,
    )
  ) {
    cols.push({
      title: 'client-loan.table.deal-schedule.reference-rate',
      value: row => (
        <PercentDisplay
          value={row.referenceRate}
          className={classNames({
            'deal-schedule-list__reference-rate-highlight':
              row.referenceRate !== 0,
          })}
        />
      ),
    });
  }
  return {
    idField: 'paymentDate',
    cols,
  };
}

function renderNumber(value) {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator=" "
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

const arrayMapping = {
  nominal_balances: 'nominalBalance',
  nominal_amortizations: 'nominalAmortization',
  payment_dates: 'paymentDate',
  interest_cashflows: 'cashFlowInterest',
  coupon_rates: 'couponRate',
  reference_rates: 'referenceRate',
  strikes: 'strike',
};
export default DealScheduleList;
