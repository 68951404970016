import React from 'react';
import MuiDatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import colors from '../../colors';

const styles = () => ({
  container: {
    width: '100%',
  },
  calendarIcon: {
    fontSize: 16,
    color: colors.BODY_LIGHT,
  },
  input: {
    fontFamily: 'Vaud Medium',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '600',
  },
  textfield: {
    marginTop: 16,
  },
});

DatePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

function DatePicker({ classes, label, onChange, ...props }) {
  return (
    <MuiDatePicker
      {...props}
      className={classes.container}
      onChange={onChange}
      label={<FormattedMessage id={label} />}
      InputProps={{
        className: classes.input,
      }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          variant="standard"
          className={classes.textfield}
        />
      )}
    />
  );
}

export default withStyles(styles)(DatePicker);
