import React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import MuiModal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import colors from '../colors';

const styles = theme => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 336,
    padding: theme.spacing(4),
    outline: 'none',
    backgroundColor: colors.WHITE,
    borderRadius: 4,
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  backdrop: {
    backgroundColor: alpha(colors.DARK, 0.9),
  },
});

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classes: PropTypes.object.isRequired,
};

function Modal({ classes, isOpen, onClose, children }) {
  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <div className={classes.paper}>
        <IconButton
          className={classes.closeButton}
          aria-label="Close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </div>
    </MuiModal>
  );
}

export default withStyles(styles)(Modal);
