import { createTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import colors from './colors';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.BLUE,
    },
    text: {
      primary: colors.DARK_BLUE_2,
      secondary: colors.LIGHT_GRAY_2,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Vaud Medium", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          width: 'calc(100% * 4 / 3)',
          fontSize: '16px',
          fontWeight: 500,
          letterSpacing: 0.15,
          lineHeight: '16px',
          fontFamily: 'Vaud Medium',
          color: colors.BODY_LIGHT,
          '&$disabled': {
            color: colors.DARK_25,
          },
          '& + MuiInputBase-disabled': {
            color: colors.DARK_25,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$disabled': {
            color: colors.DARK_25,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingTop: 8,
          paddingBottom: 8,
          letterSpacing: 0.35,
          '&:disabled': {
            color: colors.DARK_25,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: colors.DARK_BLUE,
          },
          '&:hover:not($disabled):before': {
            borderBottomColor: colors.DARK_BLUE_2,
          },
          '&:after': {
            borderBottomColor: colors.LIGHT_GRAY_3,
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          backgroundColor: colors.DARK_BLUE_2,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          color: colors.LIGHT_GRAY_2,
        },
        isSelected: {
          backgroundColor: colors.DARK_BLUE_2,
          color: 'white',
          '&:hover': {
            backgroundColor: colors.DARK_BLUE_2,
          },
        },
        current: {
          color: colors.DARK_BLUE_2,
        },
      },
    },
    MuiPickersModal: {
      styleOverrides: {
        dialogAction: {
          color: colors.BLUE,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: alpha(colors.DARK, 0.1),
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: 11,
          paddingBottom: 11,
        },
      },
    },
  },
});

export default theme;
