import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionBar from '../../layout/ActionBar';
import ContentBox from '../../layout/ContentBox';
import colors from '../../colors';

const chartOptions = {
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  tooltip: {
    valueDecimals: 2,
  },
  xAxis: {
    tickLength: 0,
    labels: {
      style: {
        color: colors.LIGHT_GRAY_2,
      },
      formatter() {
        return new Date(this.value).getFullYear();
      },
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: colors.LIGHT_GRAY_2,
      },
    },
  },

  plotOptions: {
    column: {
      pointWidth: 2,
      borderWidth: 0,
      pointPadding: 0.2,
      fillOpacity: 1,
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
};

export default function InterestResetNominalsByPeriodsChart({
  current,
  strategy,
  chartType,
}) {
  const intl = useIntl();

  const chartData = {
    xAxis: {
      ...chartOptions.xAxis,
      categories: current.total.map(entry => entry.end_date),
    },
    series: [
      {
        name: intl.formatMessage({ id: 'chart.legend.master' }),
        data: current.total.map(entry => entry.nominal),
      },
    ],
  };
  if (chartType === 'masterWithStrategy') {
    chartData.series.push({
      name: intl.formatMessage({ id: 'chart.legend.with-strategy' }),
      data: strategy.total.map(entry => entry.nominal),
    });
  }
  return (
    <ContentBox>
      <ActionBar
        title={
          <>
            <span>
              <FormattedMessage id="chart.interest-reset-nominal.title" />
            </span>
            {/*
            <IconButton
              aria-label="More"
              aria-owns="some menu"
              aria-haspopup="true"
              onClick={() => {}}
            >
              <MoreHorizIcon />
            </IconButton>
        */}
          </>
        }
        subtitle={
          <FormattedMessage id="chart.interest-reset-nominal.subtitle" />
        }
        spreadWide
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={{ ...chartOptions, ...chartData }}
      />
    </ContentBox>
  );
}

InterestResetNominalsByPeriodsChart.propTypes = {
  current: PropTypes.shape({
    total: PropTypes.array,
  }),
  strategy: PropTypes.shape({
    total: PropTypes.array,
  }),
  chartType: PropTypes.string.isRequired,
};

InterestResetNominalsByPeriodsChart.defaultProps = {
  current: {
    total: [],
  },
  strategy: {
    total: [],
  },
};
