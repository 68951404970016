const InstrumentType = {
  INTEREST_RATE: 'interest_rate_swap_db',
  CAP_FLOOR: 'cap_floor_db',
  FLOATING_RATE: 'floating_rate_loan_db',
  FIXED_RATE: 'fixed_rate_loan_db',
};
export default InstrumentType;

export const InstrumentName = {
  [InstrumentType.FIXED_RATE]: 'client-details.add-option.fixed-rate-loan',
  [InstrumentType.FLOATING_RATE]:
    'client-details.add-option.floating-rate-loan',
  [InstrumentType.INTEREST_RATE]:
    'client-details.add-option.interest-rate-swap',
  [InstrumentType.CAP_FLOOR]: 'client-details.add-option.cap-floor',
};

export const InstrumentShortName = {
  [InstrumentType.FIXED_RATE]: 'create-deal.deal-type.fixed-rate-loan',
  [InstrumentType.FLOATING_RATE]: 'create-deal.deal-type.floating-rate-loan',
  [InstrumentType.INTEREST_RATE]: 'create-deal.deal-type.irs',
  [InstrumentType.CAP_FLOOR]: 'create-deal.deal-type.cap-floor',
};
