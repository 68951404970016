export const parsePortfolios = portfolios => {
  if (portfolios && portfolios.some(p => typeof p === 'object')) {
    return portfolios.map(portfolio => portfolio.portfolio_name);
  }
  if (portfolios && portfolios.some(p => typeof p === 'string')) {
    return portfolios;
  }
  return [];
};

export const parseClients = clients => {
  if (!clients) {
    return [];
  }
  if (clients && clients.some(p => typeof p === 'string')) {
    return clients;
  }
  return clients.map(client => client.client_id);
};
