import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './content-box.scss';

ContentBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasTabs: PropTypes.bool,
  noMarginTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  reducedTopMargin: PropTypes.bool,
};

function ContentBox({
  children,
  hasTabs,
  noMarginTop,
  noPaddingBottom,
  reducedTopMargin,
}) {
  const className = classNames('content-box', {
    'content-box--no-bottom-padding': noPaddingBottom,
    'content-box--no-top-margin': noMarginTop,
    'content-box--reduced-top-margin': reducedTopMargin,
    'content-box--with-tabs': hasTabs,
  });
  return <div className={className}>{children}</div>;
}

export default ContentBox;
