import borderRadius from 'highcharts-border-radius';
import Highcharts from 'highcharts';
import colors from './colors';

export default function setupHighCharts() {
  borderRadius(Highcharts);
  Highcharts.theme = {
    colors: [colors.BLUE, colors.ORANGE, colors.LIGHT_GREY_4, colors.YELLOW],
    yAxis: {
      labels: {
        style: {
          textTransform: 'uppercase',
        },
      },
      crosshair: {
        width: 2,
        dashStyle: 'dot',
      },
    },
  };
  Highcharts.setOptions(Highcharts.theme);
}
