import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PageError from './PageError';

export default class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  // eslint-disable-next-line
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    // eslint-disable-next-line
    console.warn(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? <PageError /> : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
