import React, { useState, useReducer } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Close from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Tooltip } from '../../images/icons/Tooltip.svg';
import Button from '../../components/button';
import Switch from '../../components/Switch';
import './strategy-selector.scss';

function findKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

const strategies = [
  { value: 'master', label: 'strategy-manager.selector-modal.master-switch' },
  {
    value: 'strategy',
    label: 'strategy-manager.selector-modal.strategy-switch',
  },
];

const ActionType = {
  REMOVE: 'remove',
  SET: 'set',
};

function removeStrategySelection(portfolioName) {
  return {
    type: ActionType.REMOVE,
    portfolioName,
  };
}

function setStrategy(portfolioName, value) {
  return {
    type: ActionType.SET,
    value,
    portfolioName,
  };
}

const strategySelectReducer = (state, action) => {
  switch (action.type) {
    case ActionType.REMOVE: {
      return {
        ...state,
        [action.portfolioName]: null,
      };
    }
    case ActionType.SET: {
      return {
        ...state,
        [action.portfolioName]: action.value,
      };
    }
    default:
      return state;
  }
};

StrategySelector.propTypes = {
  portfolios: PropTypes.array.isRequired,
  onStrategiesSave: PropTypes.func.isRequired,
};

function StrategySelector({ portfolios, onStrategiesSave }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStrategies, dispatchStrategyChange] = useReducer(
    strategySelectReducer,
    {},
  );
  const [enhanceTip, setEnhanceTip] = useState(false);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSave() {
    if (findKeyByValue(selectedStrategies, 'master')) {
      handleClose();
      onStrategiesSave(selectedStrategies);
      setEnhanceTip(false);
    } else {
      setEnhanceTip(true);
    }
  }

  const open = !!anchorEl;

  const selectedPorfolios = Object.values(selectedStrategies).filter(
    v => !!v,
  ).length;
  return (
    <>
      <Button size="s" buttonType="solid" onClick={handleClick}>
        strategy-manager.button.strategy-manager
      </Button>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="strategy-selector">
          <div className="strategy-selector__header">
            <div>
              <span className="strategy-selector__header__icon-base">
                <Tooltip />
              </span>
            </div>
            <div className="strategy-selector__header__tooltips">
              <span>
                <FormattedMessage id="strategy-manager.selector-modal.tooltip" />
              </span>
              <br />
              <span
                className={classNames('strategy-selector__tip', {
                  'strategy-selector__tip--enhanced': enhanceTip,
                })}
              >
                <FormattedMessage id="strategy-manager.selector-modal.enhance-tip" />
              </span>
            </div>
          </div>
          <ul className="strategy-selector__content">
            {portfolios.map(p => {
              const name = p.portfolio_name;
              const isPortfolioSelected = !!selectedStrategies[name];
              return (
                <li key={name}>
                  <span
                    className={classNames('strategy-selector__item__title', {
                      'strategy-selector__item__title--selected':
                        isPortfolioSelected,
                    })}
                  >
                    {name}
                  </span>
                  <Switch
                    name={name}
                    options={strategies}
                    value={selectedStrategies[name]}
                    onChange={value =>
                      dispatchStrategyChange(setStrategy(name, value))
                    }
                  />
                  <span className="strategy-selector__item__remove">
                    {isPortfolioSelected && (
                      <IconButton
                        data-testid="remove-strategy"
                        onClick={() =>
                          dispatchStrategyChange(removeStrategySelection(name))
                        }
                      >
                        <Close />
                      </IconButton>
                    )}
                  </span>
                </li>
              );
            })}
          </ul>
          <div className="strategy-selector__footer">
            <div>
              <FormattedMessage
                id="strategy-manager.selector-modal.portfolios-selected"
                values={{ amount: selectedPorfolios }}
              />
            </div>
            <div>
              <Button size="s" buttonType="outlined" onClick={handleClose}>
                common.button.cancel
              </Button>
              <Button size="s" buttonType="solid" onClick={handleSave}>
                common.button.create
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default StrategySelector;
