import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import styled from '@emotion/styled';

import './page-header.scss';

const StyledBreadCrumbs = styled(Breadcrumbs)`
  font-size: 0.825rem;
`;

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      path: PropTypes.string,
    }),
  ),
  moreButtonOptions: PropTypes.shape({
    values: PropTypes.array,
    valueKey: PropTypes.string,
    titleKey: PropTypes.string,
    onOptionSelected: PropTypes.func,
  }),
};

PageHeader.defaultProps = {
  breadcrumbs: null,
  moreButtonOptions: null,
};

function PageHeader({ breadcrumbs, moreButtonOptions, children }) {
  function renderBreadcrumbs(values) {
    return (
      <div className="page-header__breadcrumbs">
        <StyledBreadCrumbs
          separator={
            <div className="page-header__breadcrumbs__separator">/</div>
          }
          arial-label="Breadcrumb"
        >
          {values.map((val, index) => {
            if (index === values.length - 1) {
              return (
                <div
                  className="page-header__breadcrumbs__title__current"
                  key={val.path}
                >
                  {val.title}
                </div>
              );
            }
            return (
              <Link key={val.path} to={val.path}>
                <div className="page-header__breadcrumbs__title">
                  {val.title}
                </div>
              </Link>
            );
          })}
        </StyledBreadCrumbs>
      </div>
    );
  }

  return (
    <div className="page-header">
      <div className="page-header__left">
        <div className="page-header__left__content">
          {breadcrumbs && renderBreadcrumbs(breadcrumbs)}
        </div>
      </div>
      <div className="page-header__content">{children}</div>
    </div>
  );
}

export default PageHeader;
