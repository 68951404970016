import React from 'react';
import PropTypes from 'prop-types';

import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FormattedMessage } from 'react-intl';

export default function Checkbox({ label, value, ...props }) {
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 25 }} />}
          checkedIcon={<CheckBoxIcon style={{ fontSize: 25 }} />}
          checked={value}
          {...props}
        />
      }
      label={
        <Typography style={{ fontSize: 16, fontWeight: 500 }}>
          <FormattedMessage id={label} />
        </Typography>
      }
    />
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
};
