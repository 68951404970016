import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import SideNav from './SideNav';
import './main-layout.scss';

export default function MainLayoutContainer({ children }) {
  return (
    <div className="main-layout">
      <div className="main-layout__left">
        <SideNav />
      </div>
      <div className="main-layout__right">
        <Header />
        <div className="main-layout__content">{children}</div>
        <div id="sticky-footer-root" />
      </div>
    </div>
  );
}

MainLayoutContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
