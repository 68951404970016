import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionBar from '../../layout/ActionBar';
import ContentBox from '../../layout/ContentBox';

const setOptions = {
  lang: {
    thousandsSep: ' ',
    decimalPoint: '.',
  },
};

const chartOptions = {
  title: {
    text: '',
  },
  chart: {
    type: 'pie',
  },
  legend: {
    align: 'right',
    itemMarginBottom: 10,
    itemMarginTop: 10,
    layout: 'vertical',
    verticalAlign: 'middle',
  },
  tooltip: {
    pointFormat: '<b>{point.y}</b><br/>{point.percentage:.1f}%',
    valueDecimals: 2,
  },
  plotOptions: {
    pie: {
      innerSize: '80%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      data: [],
    },
  ],
};

export default function PieChart({ data, title }) {
  const intl = useIntl();
  const chartData = {
    series: [
      {
        data: data.total.map(entry => [
          intl.formatMessage({
            id: `chart.pie.segment.${entry.instrument_type}`,
          }),
          entry.nominal_balance,
        ]),
      },
    ],
  };

  return (
    <ContentBox>
      <ActionBar
        title={
          <>
            <span>
              <FormattedMessage id={title} />
            </span>
            {/*
            <IconButton
              aria-label="More"
              aria-owns="some menu"
              aria-haspopup="true"
              onClick={() => {}}
            >
              <MoreHorizIcon />
            </IconButton>
        */}
          </>
        }
        spreadWide
      />
      <div style={{ maxWidth: 600 }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{ ...chartOptions, ...setOptions, ...chartData }}
        />
      </div>
    </ContentBox>
  );
}

PieChart.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.array,
  }),
  title: PropTypes.string.isRequired,
};

PieChart.defaultProps = {
  data: {
    total: [],
  },
};
