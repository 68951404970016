import React from 'react';
import { ErrorMessage, Field } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import RawTextField from '../Input';
import RawCheckbox from '../Checkbox';
import RawSelect from '../Select';
import RawSelectWithCreate from '../SelectWithCreate';
import FormikDatePicker from './FormikDatePicker';

function enhanceWithFormikControls(Component, defaultValue) {
  ComponentWithFormik.propTypes = {
    ...Field.propTypes,
    ...Component.propTypes,
  };

  function ComponentWithFormik({ field, ...props }) {
    return (
      <Component
        name={field.name}
        value={
          field.value !== null && field.value !== undefined
            ? field.value
            : defaultValue
        }
        onChange={(...args) => {
          field.onChange(...args);
        }}
        onBlur={field.onBlur}
        {...props}
      />
    );
  }

  return ComponentWithFormik;
}

function enhanceWithFormikField(Component, defaultValue) {
  ComponentWithFormik.propTypes = {
    ...Field.propTypes,
    ...Component.propTypes,
  };
  const WithFormikControls = enhanceWithFormikControls(Component, defaultValue);

  function ComponentWithFormik(props) {
    const { name } = props;
    return (
      <>
        <Field component={WithFormikControls} {...props} />
        <ErrorMessage
          name={name}
          component={FormHelperText}
          style={{ color: 'red', marginTop: '0' }}
        />
      </>
    );
  }

  return ComponentWithFormik;
}

export const Select = enhanceWithFormikField(RawSelect, null);
export const SelectWithCreate = enhanceWithFormikField(
  RawSelectWithCreate,
  null,
);
export const DatePicker = enhanceWithFormikField(FormikDatePicker, null);
export const TextField = enhanceWithFormikField(RawTextField, '');
export const Checkbox = enhanceWithFormikField(RawCheckbox, false);
