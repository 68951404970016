import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { FormattedMessage } from 'react-intl';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import FormHelperText from '@mui/material/FormHelperText';
import Input from './Input';
import colors from '../../colors';

const useStyles = makeStyles(() => ({
  inputBase: {
    cursor: 'pointer',
    color: 'transparent',
    textShadow: `0 0 0 ${colors.DARK_BLUE_2}`,
  },
  addItemsContainer: {
    width: 260,
    margin: '4px 24px 4px 8px',
    '&:focus': {
      outline: 'none',
    },
  },
  dropdownIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  addItemIcon: { color: colors.BLUE, width: 20, height: 20 },
  addItemInput: {
    color: colors.DARK_25,
    marginRight: 8,
    marginLeft: 8,
  },
  option: { width: '100%', textAlign: 'right', marginRight: 10 },
}));

SelectWithCreate.propTypes = {
  autoOk: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  createItemPlaceHolder: PropTypes.string,
  onChange: PropTypes.func,
  onCreateItem: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      text: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
};

export default function SelectWithCreate({
  id,
  name,
  onChange,
  value,
  options,
  createItemPlaceHolder,
  onCreateItem,
  autoOk,
  ...rest
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [createItemValue, setCreateItemValue] = useState('');
  const [createItemError, setCreateItemError] = useState('');
  const createInputRef = useRef();
  const classes = useStyles();

  function openCreateMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function closeCreateMenu() {
    setAnchorEl(null);
  }

  function handleCreateNewItem() {
    if (!createItemValue) {
      setCreateItemError('Value is required');
      return;
    }
    if (findOptionByValue(createItemValue)) {
      setCreateItemError('Value already exists');
      return;
    }
    onCreateItem(createItemValue);
    setCreateItemValue('');
    if (autoOk) {
      onChange({
        target: {
          name,
          value: createItemValue,
        },
      });
      closeCreateMenu();
    }
  }

  function handleNewItemTextChange(inputValue) {
    setCreateItemValue(inputValue);
    setCreateItemError('');
  }

  const handleKeyDown = e => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      handleCreateNewItem();
      e.preventDefault();
    }
  };

  function findOptionByValue(v) {
    if (!v) {
      return '';
    }
    return options.find(o => o.value.toUpperCase() === v.toUpperCase());
  }

  const selectedOption = findOptionByValue(value);

  return (
    <>
      <Input
        id={id}
        name={name}
        value={
          (selectedOption && (selectedOption.label || selectedOption.text)) ||
          ''
        }
        onClick={openCreateMenu}
        onFocus={e => e.preventDefault()}
        onChange={() => ''}
        endAdornment={<ArrowDropDown className={classes.dropdownIcon} />}
        inputProps={{ className: classes.inputBase }}
        {...rest}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeCreateMenu}
      >
        <div className={classes.addItemsContainer}>
          <Input
            autoFocus
            id={id + 'create-item'}
            name={id + 'create-item'}
            placeholder={createItemPlaceHolder}
            value={createItemValue}
            onChange={e => handleNewItemTextChange(e.target.value)}
            onKeyDown={handleKeyDown}
            inputProps={{ className: classes.addItemInput }}
            inputRef={createInputRef}
            error={!!createItemError}
            endAdornment={
              <IconButton
                onClick={handleCreateNewItem}
                data-testid="create-item-button"
              >
                <AddCircleOutline className={classes.addItemIcon} />
              </IconButton>
            }
          />
          {createItemError && (
            <FormHelperText style={{ color: 'red' }}>
              {createItemError}
            </FormHelperText>
          )}
        </div>
        <hr />
        {options.map(o => (
          <MenuItem
            key={o.value}
            onClick={() => {
              onChange({
                target: {
                  name,
                  value: o.value,
                },
              });
              closeCreateMenu();
            }}
          >
            <div className={classes.option}>
              {o.label ? <FormattedMessage id={o.label} /> : o.text}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
