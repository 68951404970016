import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionBar from '../../layout/ActionBar';
import ContentBox from '../../layout/ContentBox';
import colors from '../../colors';

const chartOptions = {
  chart: {
    type: 'line',
  },
  colors: [colors.LIGHT_GREY_4, colors.RED, colors.YELLOW],
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  tooltip: {
    valueDecimals: 4,
    valueSuffix: '%',
  },
  xAxis: {
    tickLength: 0,
    labels: {
      style: {
        color: colors.LIGHT_GRAY_2,
      },
      formatter() {
        return new Date(this.value).getFullYear();
      },
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: colors.LIGHT_GRAY_2,
      },
      format: '{value:.2f}%',
    },
  },
};

export default function InterestEffectiveRatesByScenarioAndPeriodChart({
  total,
  titleSuffix,
}) {
  const intl = useIntl();

  const chartData = {
    xAxis: {
      ...chartOptions.xAxis,
      categories: total.market.map(entry => entry.end_date),
    },
    series: [
      {
        name: intl.formatMessage({ id: 'chart.legend.market' }),
        data: total.market.map(entry => -entry.value * 100),
      },
      {
        name: intl.formatMessage({ id: 'chart.legend.market-up' }),
        data: total.market_up.map(entry => -entry.value * 100),
      },
      {
        name: intl.formatMessage({ id: 'chart.legend.market-down' }),
        data: total.market_down.map(entry => -entry.value * 100),
      },
    ],
  };
  return (
    <ContentBox>
      <ActionBar
        title={
          <>
            <span>
              <FormattedMessage
                id={`chart.interest-effective-rates-by-scenario-and-period-${titleSuffix}.title`}
              />
            </span>
            {/*
            <IconButton
              aria-label="More"
              aria-owns="some menu"
              aria-haspopup="true"
              onClick={() => {}}
            >
              <MoreHorizIcon />
            </IconButton>
        */}
          </>
        }
        subtitle={
          <FormattedMessage id="chart.interest-effective-rates-by-scenario-and-period.subtitle" />
        }
        spreadWide
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={{ ...chartOptions, ...chartData }}
      />
    </ContentBox>
  );
}

InterestEffectiveRatesByScenarioAndPeriodChart.propTypes = {
  total: PropTypes.shape({
    market: PropTypes.array,
    market_up: PropTypes.array,
    market_down: PropTypes.array,
  }),
  titleSuffix: PropTypes.string,
};

InterestEffectiveRatesByScenarioAndPeriodChart.defaultProps = {
  total: {
    market: [],
    market_up: [],
    market_down: [],
  },
};
