import React from 'react';
import classNames from 'classnames';
import './button.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from 'react-intl';

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  fillWidth: PropTypes.bool,
  buttonType: PropTypes.oneOf([
    'outlined',
    'solid',
    'solid-inverted',
    'outlined-inverted',
  ]),
  loading: PropTypes.bool,
  uppercase: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  size: 'l',
  buttonType: 'solid',
  loading: false,
  uppercase: false,
};

export default function Button({
  size,
  buttonType,
  loading,
  uppercase,
  children,
  to,
  fillWidth,
  onClick,
  ...rest
}) {
  const className = classNames('button', size, buttonType, {
    upper: uppercase,
    'fill-width': fillWidth,
    button__link: !!to,
  });
  const content = (
    <>
      {loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <FormattedMessage id={children} />
      )}
    </>
  );
  if (to) {
    return (
      <Link className={className} to={to} {...rest}>
        {content}
      </Link>
    );
  }
  return (
    <button
      className={className}
      type="button"
      onClick={loading ? null : onClick}
      {...rest}
    >
      {content}
    </button>
  );
}
