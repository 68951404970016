import { useState, useEffect } from 'react';
import api from './api';

export default function useApiGet(path) {
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    api
      .get(path)
      .then(res => {
        setResult(res);
      })
      .catch(e => setError(e))
      .finally(() => setIsLoading(false));
  }, [path]);

  return [result, isLoading, error];
}
