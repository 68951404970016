import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import PageHeader from '../../components/page-header';
import UnhedgedNominalsChart from '../../components/charts/UnhedgedNominalsChart';
import InterestResetNominalsByPeriodsChart from '../../components/charts/InterestResetNominalsByPeriodsChart';
import InterestExpenseByScenarioAndPeriodChart from '../../components/charts/InterestExpenseByScenarioAndPeriodChart';
import InterestEffectiveRatesByScenarioAndPeriodChart from '../../components/charts/InterestEffectiveRatesByScenarioAndPeriodChart';
import InterestSensitivityByScenarioChart from '../../components/charts/InterestSensitivityByScenarioChart';
// import InterestSensitivityByTimeBucketsChart from '../../components/charts/InterestSensitivityByTimeBucketsChart';
import StrategySelector from './StrategySelector';
import LoadingSpinner from '../../components/LoadingSpinner';
import PageError from '../../components/PageError';
import { ERRORS_BY_STATUS_CODE } from '../../constants';
import CurrentUserContext from '../../CurrentUserContext';
import api from '../../utils/api';
import KPIBox from '../../components/kpis/KPIBox';
import StrategyMetrics from './StrategyMetrics';

function getKeyByValue(object, value) {
  return Object.keys(object)
    .filter(key => object[key] === value)
    .map(str => encodeURIComponent(str));
}

function getReportQueryFromPortfolios(portfolios) {
  const masterPortfolios = getKeyByValue(portfolios, 'master');
  const masterQuery = `current_portfolio_names=${masterPortfolios}`;
  const strategyPortfolios = getKeyByValue(portfolios, 'strategy');
  if (strategyPortfolios.length !== 0) {
    const strategyQuery = `strategy_portfolio_names=${strategyPortfolios}`;
    return `${masterQuery}&${strategyQuery}`;
  }
  return `${masterQuery}`;
}

function StrategyManagerPage() {
  const { state } = useLocation();
  const chartType =
    getKeyByValue(state.portfolios, 'strategy').length === 0
      ? 'masterOnly'
      : 'masterWithStrategy';

  const { setUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const { clientId } = useParams();

  const [portfolios, setPortfolios] = useState(null);
  const [isLoadingPortfolios, setIsLoadingPortfolios] = useState(true);
  const [reports, setReports] = useState(null);
  const [isLoadingReports, setIsLoadingReports] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  const fetch = async () => {
    api
      .get(`/portfoliomanager?client_id=${clientId}`)
      .then(data => {
        setPortfolios(data);
        const reportsRequest = getReportQueryFromPortfolios(state.portfolios);
        return api.get(
          `/StrategyManagerReports?client_id=${clientId}&${reportsRequest}`,
        );
      })
      .then(data => {
        setReports(data);
      })
      .catch(error => {
        setFetchError(error);
      })
      .finally(() => {
        setIsLoadingPortfolios(false);
        setIsLoadingReports(false);
      });
  };

  useEffect(() => {
    setIsLoadingPortfolios(true);
    setIsLoadingReports(true);
    fetch();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [state]);

  if (isLoadingPortfolios || isLoadingReports) {
    return <LoadingSpinner />;
  }

  if (fetchError) {
    const msg =
      ERRORS_BY_STATUS_CODE[fetchError.response.status] || 'Unknown error.';
    setUser(null);
    return <PageError customMessage={msg} redirectToHome />;
  }

  function onStrategiesSave(data) {
    navigate(`/clients/${clientId}/strategy-manager`, {
      state: { portfolios: data },
      replace: true,
    });
  }

  const getKPIItems = function () {
    const {
      floating_nominal_balance,
      nominal_balance,
      coupon,
      wam_esma,
      wal_esma,
    } = reports.key_statistics_by_deal.current.total;

    return [
      {
        value: Number(floating_nominal_balance * 100).toFixed(1),
        suffix: '%',
        label: 'strategy-manager.metrics.floating-nominal',
      },
      {
        value: (nominal_balance / 1000000).toFixed(1),
        suffix: 'M',
        label: 'strategy-manager.metrics.total-nominal',
      },
      {
        value: (coupon * 100).toFixed(2),
        suffix: '%',
        label: 'strategy-manager.metrics.avg-coupon-rate',
      },
      {
        value: wam_esma.toFixed(1),
        suffix: 'Y',
        label: 'strategy-manager.metrics.zinsbindung',
      },
      {
        value: wal_esma.toFixed(1),
        suffix: 'Y',
        label: 'strategy-manager.metrics.kapitalbindung',
      },
    ];
  };

  const kpiItems = getKPIItems();

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            title: <FormattedMessage id="client-manager.page-title" />,
            path: '/clients',
          },
          {
            title: clientId,
            path: `/clients/${clientId}`,
          },
          {
            title: <FormattedMessage id="strategy-manager.page-title" />,
            path: `/clients/${clientId}/strategy-manager`,
          },
        ]}
      >
        <StrategySelector
          onStrategiesSave={onStrategiesSave}
          portfolios={portfolios}
        />
      </PageHeader>
      <KPIBox title="strategy-manager.metrics.title" items={kpiItems} />
      {chartType === 'masterWithStrategy' && (
        <StrategyMetrics
          title="strategy-manager.strategy-metrics.title"
          current={reports.key_statistics_by_deal.current}
          strategy={reports.key_statistics_by_deal.strategy}
        />
      )}
      <UnhedgedNominalsChart
        {...reports.fixed_floating_nominal_by_period}
        chartType={chartType}
      />
      <InterestExpenseByScenarioAndPeriodChart
        {...reports.interest_expense_by_scenario_and_period.current}
        titleSuffix="current"
      />
      <InterestExpenseByScenarioAndPeriodChart
        {...reports.interest_expense_by_scenario_and_period.strategy}
        titleSuffix="strategy"
      />
      <InterestEffectiveRatesByScenarioAndPeriodChart
        {...reports.interest_effective_rates_by_scenario_and_period.current}
        titleSuffix="current"
      />
      <InterestEffectiveRatesByScenarioAndPeriodChart
        {...reports.interest_effective_rates_by_scenario_and_period.strategy}
        titleSuffix="strategy"
      />
      <InterestResetNominalsByPeriodsChart
        {...reports.reset_nominal_by_deal_and_period}
        chartType={chartType}
      />
      <InterestSensitivityByScenarioChart
        type="interest-with-nominal"
        data={{
          ...reports.cash_flow_difference_by_deal_and_scenario,
          chartType,
        }}
      />
      {/* <InterestSensitivityByTimeBucketsChart
        {...reports.cash_flow_difference_by_deal_and_period}
        chartType={chartType}
      /> */}
    </>
  );
}

export default StrategyManagerPage;
