import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginOAuthRedirectPage from './pages/login/LoginOAuthRedirectPage';
import LoginOAuthCallbackPage from './pages/login/LoginOAuthCallbackPage';
import RiskDashboardPage from './pages/dashboard/DashboardPage';
import ClientManagerPage from './pages/client-manager/ClientManagerPage';
import ThresholdMonitoringPage from './pages/threshold-monitoring/ThresholdMonitoringPage';
import NotFoundPage from './pages/NotFoundPage';
import MainLayoutContainer from './layout/MainLayoutContainer';
import ClientDetailsPage from './pages/client-manager/client-details/ClientDetailsPage';
import ClientLoanPage from './pages/client-manager/create-deal/ClientLoanPage';
import StrategyManagerPage from './pages/strategy-manager/StrategyManagerPage';
import CreateDealPage from './pages/client-manager/create-deal/CreateDealPage';
import { ReleaseNotesPage } from './pages/release-notes/ReleaseNotesPage';
import AuthRoute from './components/AuthRoute';

function withMainLayout(Component) {
  return function EnhancedComponent(props) {
    return (
      <MainLayoutContainer>
        <Component {...props} />
      </MainLayoutContainer>
    );
  };
}

const ClientManagerPageWithLayout = withMainLayout(ClientManagerPage);
const RiskDashboardPageWithLayout = withMainLayout(RiskDashboardPage);
const ClientDetailsPageWithLayout = withMainLayout(ClientDetailsPage);
const StrategyManagerPageWithLayout = withMainLayout(StrategyManagerPage);
const ClientLoanPageWithLayout = withMainLayout(ClientLoanPage);
const CreateDealPageWithLayout = withMainLayout(CreateDealPage);
const ThresholdMonitoringPageWithLayout = withMainLayout(
  ThresholdMonitoringPage,
);
const ReleaseNotesWithLayout = withMainLayout(ReleaseNotesPage);
const NotFoundPageWithLayout = withMainLayout(NotFoundPage);

export default function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LoginOAuthRedirectPage />} />
      <Route path="/oauth_callback" element={<LoginOAuthCallbackPage />} />
      <Route
        path="/risk-dashboard"
        element={
          <AuthRoute>
            <RiskDashboardPageWithLayout />
          </AuthRoute>
        }
      />
      <Route
        path="/clients"
        element={
          <AuthRoute>
            <ClientManagerPageWithLayout />
          </AuthRoute>
        }
      />
      <Route
        path="/clients/:clientId"
        element={
          <AuthRoute>
            <ClientDetailsPageWithLayout />
          </AuthRoute>
        }
      />
      <Route
        path="/clients/:clientId/strategy-manager"
        element={
          <AuthRoute>
            <StrategyManagerPageWithLayout />
          </AuthRoute>
        }
      />
      <Route
        path="/clients/:clientId/deals/:dealId"
        element={
          <AuthRoute>
            <ClientLoanPageWithLayout />
          </AuthRoute>
        }
      />
      <Route
        path="/clients/create-deal/:instrumentType"
        element={
          <AuthRoute>
            <CreateDealPageWithLayout />
          </AuthRoute>
        }
      />
      <Route
        path="/clients/:clientId/create-deal/:instrumentType"
        element={
          <AuthRoute>
            <CreateDealPageWithLayout />
          </AuthRoute>
        }
      />
      <Route
        path="/threshold-monitoring"
        element={
          <AuthRoute>
            <ThresholdMonitoringPageWithLayout />
          </AuthRoute>
        }
      />
      <Route
        path="/release-notes"
        element={
          <AuthRoute>
            <ReleaseNotesWithLayout />
          </AuthRoute>
        }
      />
      <Route
        element={
          <AuthRoute>
            <NotFoundPageWithLayout />
          </AuthRoute>
        }
      />
    </Routes>
  );
}
