import { useMemo, useRef, useState } from 'react';

const ASCENDING = 'asc';
const DESCENDING = 'desc';

export default function useSorting(
  list,
  initialOrderBy,
  colConfigs,
  initiallyAscending = true,
) {
  const colConfigRef = useRef(colConfigs);

  const [order, setOrder] = useState(
    initiallyAscending ? ASCENDING : DESCENDING,
  );
  const initialOrderedList = useMemo(
    () =>
      sortByColumn(
        list,
        findColumnName(colConfigRef.current, initialOrderBy),
        !initiallyAscending,
      ),
    [initiallyAscending, initialOrderBy, list],
  );

  const [orderedList, setOrderedList] = useState(initialOrderedList);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const onRequestSort = (event, property) => {
    const orderByColumn = findColumnName(colConfigRef.current, property);
    if (property === orderBy) {
      const newOrder = order === ASCENDING ? DESCENDING : ASCENDING;
      setOrder(newOrder);
      setOrderedList(
        sortByColumn(orderedList, orderByColumn, newOrder === DESCENDING),
      );
    } else {
      setOrderBy(property);
      setOrder(ASCENDING);
      setOrderedList(sortByColumn(orderedList, orderByColumn, false));
    }
  };
  return [orderedList, orderBy, order, onRequestSort];
}

const findColumnName = (colConfigs, orderBy) => {
  const correctCol = colConfigs.find(config => config.key === orderBy);
  if (!correctCol) {
    return null;
  }
  return correctCol.sortableColumn;
};

const sortByColumn = (list, column, isDescending) => {
  const indexMap = new Map();
  list.forEach((item, index) => {
    indexMap.set(item, index);
  });

  return list.sort((objectOne, objectTwo) => {
    const valueOne = objectOne[column];
    const valueTwo = objectTwo[column];

    return compareValues(
      valueOne,
      valueTwo,
      isDescending,
      indexMap.get(objectOne) - indexMap.get(objectTwo),
    );
  });
};

const compareValues = (firstValue, secondValue, isDescending, equal) => {
  if (
    firstValue < secondValue ||
    (firstValue === null && secondValue !== null) ||
    (firstValue === undefined && secondValue !== undefined)
  ) {
    return isDescending ? -1 : 1;
  }
  if (
    firstValue > secondValue ||
    (firstValue !== null && secondValue === null) ||
    (firstValue !== undefined && secondValue === undefined)
  ) {
    return isDescending ? 1 : -1;
  }
  return equal;
};
