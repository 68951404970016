import { useState } from 'react';
import api from './api';
import useEffectRepeat from './useEffectRepeat';

export default function useApiGet(path) {
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const repeat = useEffectRepeat(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
    api
      .get(path)
      .then(res => {
        setResult(res);
      })
      .catch(e => setError(e))
      .finally(() => setIsLoading(false));
  }, [path]);

  return { state: [result, isLoading, error], repeat, setResult };
}
