import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './form-control-footer.scss';
import Button from '../button';
import StickyFooterPortal from '../../layout/StickyFooterPortal';

FormControlFooter.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
};

FormControlFooter.defaultProps = {
  title: '',
  description: '',
};

export default function FormControlFooter({
  onSave,
  onCancel,
  title,
  description,
}) {
  return (
    <StickyFooterPortal>
      <div className="form-control-footer">
        <div className="form-control-footer__description">
          <strong>
            <FormattedMessage id={title} />
          </strong>
          <p>
            <small>
              <FormattedMessage id={description} />
            </small>
          </p>
        </div>
        <div className="form-control-footer__actions">
          <Button size="m" buttonType="outlined-inverted" onClick={onCancel}>
            common.button.cancel
          </Button>
          <Button size="m" buttonType="solid-inverted" onClick={onSave}>
            common.button.save
          </Button>
        </div>
      </div>
    </StickyFooterPortal>
  );
}
