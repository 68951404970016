import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ClientList from './ClientList';
import PageHeader from '../../components/page-header';
import useApiGetRepeat from '../../utils/useApiGetRepeat';
import LoadingSpinner from '../../components/LoadingSpinner';
import Modal from '../../components/Modal';
import PageError from '../../components/PageError';
import useStateBoolean from '../../utils/useStateBoolean';
import DealUpload from './deal-upload/DealUpload';
import { ERRORS_BY_STATUS_CODE } from '../../constants';
import CurrentUserContext from '../../CurrentUserContext';
import { AddDealButton } from './AddDealButton';
import { SectionTabs } from '../../layout/SectionTabs';
import KPIBox from '../../components/kpis/KPIBox';
import { nFormatter } from '../../utils/formatters';

const tabs = [
  { key: 'own', content: <FormattedMessage id="section-tab.own-clients" /> },
  {
    key: 'regional',
    content: <FormattedMessage id="section-tab.regional-clients" />,
  },
];

const tabUrls = {
  own: '/clientmanager',
  regional: '/clientmanager?account_group=region',
};

export default function ClientManagerPage() {
  const [isOpen, openModal, closeModal] = useStateBoolean(false);
  const { setUser } = useContext(CurrentUserContext);
  const [activeTabKey, setActiveTabKey] = useState('own');
  const navigate = useNavigate();

  const {
    state: [clients, isLoading, error],
    repeat,
  } = useApiGetRepeat(tabUrls[activeTabKey]);

  const handleCloseModal = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    closeModal();
  };

  function onDealUploadFinish() {
    closeModal();
    repeat();
  }

  function onClientSelect(id) {
    navigate(
      `/clients/${encodeURIComponent(id)}${
        activeTabKey === 'regional' ? '?account_group=region' : ''
      }`,
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    const errorCode = error.response.status;
    const msg = ERRORS_BY_STATUS_CODE[errorCode] || 'Unknown error.';
    setUser(null);
    return <PageError customMessage={msg} redirectToHome />;
  }

  function handleInstrumentRedirect(instrumentType) {
    navigate(`/clients/create-deal/${instrumentType}`);
  }

  const handleTabChange = key => {
    setActiveTabKey(key);
  };

  const getKPIItems = function () {
    const metrics = clients
      ? clients.reduce(
          (totals, client) => ({
            dealCount: client.nr_of_deals + totals.dealCount,
            npvTotal: client.npv + totals.npvTotal,
            underLyingsCount:
              client.nr_of_underlyings + totals.underLyingsCount,
            derivativesCount:
              client.nr_of_derivatives + totals.derivativesCount,
          }),
          {
            dealCount: 0,
            npvTotal: 0,
            underLyingsCount: 0,
            derivativesCount: 0,
          },
        )
      : {
          dealCount: 0,
          npvTotal: 0,
          underLyingsCount: 0,
          derivativesCount: 0,
        };

    const [npvValue, npvSuffix] = nFormatter(metrics.npvTotal, 2);

    return [
      {
        value: Array.isArray(clients) ? clients.length : 0,
        label: 'client-manager.metrics.clients',
      },
      {
        value: metrics.dealCount,
        label: 'client-manager.metrics.deals',
      },
      {
        value: metrics.derivativesCount,
        label: 'client-manager.metrics.derivatives',
      },
      {
        value: metrics.underLyingsCount,
        label: 'client-manager.metrics.underlyings',
      },
      {
        value: npvValue,
        suffix: npvSuffix,
        label: 'client-manager.metrics.npv',
      },
    ];
  };

  const kpiItems = getKPIItems();

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            title: <FormattedMessage id="client-manager.page-title" />,
            path: '/clients',
          },
        ]}
      />
      <SectionTabs
        activeTabKey={activeTabKey}
        handleTabChange={handleTabChange}
        tabs={tabs}
      />
      <KPIBox title="client-manager.metrics.title" items={kpiItems} hasTabs />
      <ClientList
        actions={
          <AddDealButton
            handleInstrumentRedirect={handleInstrumentRedirect}
            openModal={openModal}
          />
        }
        clients={clients}
        isLoading={isLoading}
        isRegional={activeTabKey === 'regional'}
        onClientSelect={onClientSelect}
      />
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <DealUpload onFinish={onDealUploadFinish} />
      </Modal>
    </>
  );
}
