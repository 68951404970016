import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import {
  DatePicker,
  Select,
  TextField,
} from '../../../../components/forms/formik';
import {
  BUSINESS_DAY_CONVENTIONS,
  DAY_COUNT_CONVENTIONS,
  HOLIDAY_CALENDARS,
} from '../../utils/lookup-values';
import DoubleCheckbox from '../../../../components/forms/DoubleCheckbox';
import { DEFAULT_DATE_FORMAT } from '../../../../constants';
import {
  composeValidators,
  range,
} from '../../../../components/forms/formik/validators';
import FormikPercentageInput from '../../../../components/forms/formik/input-types/FormikPercentageInput';

export const LegConventionInputs = ({
  businessDayBindings,
  classes,
  dayCountBindings,
  firstPaymentDateBindings,
  legType,
  paymentDayBindings,
}) => (
  <>
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.row}>
        <Select
          id={`${legType}-leg-loan-business-day-convention`}
          label="interest-rate-form.field.business-day-convention"
          name={`${legType}_leg_parameters.business_day_convention`}
          margin="normal"
          options={BUSINESS_DAY_CONVENTIONS}
          onChange={
            legType === 'pay'
              ? businessDayBindings.onChange2
              : businessDayBindings.onChange1
          }
        />
        <DoubleCheckbox
          checked={businessDayBindings.enabled}
          onChange={
            legType === 'pay'
              ? businessDayBindings.togglePath2
              : businessDayBindings.togglePath1
          }
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.row}>
        <Select
          id={`${legType}-leg-loan-day-count-conventions`}
          label="interest-rate-form.field.day-count-convention"
          name={`${legType}_leg_parameters.day_count`}
          margin="normal"
          options={DAY_COUNT_CONVENTIONS}
          onChange={
            legType === 'pay'
              ? dayCountBindings.onChange2
              : dayCountBindings.onChange1
          }
        />
        <DoubleCheckbox
          checked={dayCountBindings.enabled}
          onChange={
            legType === 'pay'
              ? dayCountBindings.togglePath2
              : dayCountBindings.togglePath1
          }
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.row}>
        <DatePicker
          id={`${legType}-leg-loan-first-payment-date`}
          label="interest-rate-form.field.first-payment-date"
          name={`${legType}_leg_parameters.first_payment_date`}
          margin="normal"
          onChange={
            legType === 'pay'
              ? firstPaymentDateBindings.onChange2
              : dayCountBindings.onChange1
          }
          format={DEFAULT_DATE_FORMAT}
        />
        <DoubleCheckbox
          checked={firstPaymentDateBindings.enabled}
          onChange={
            legType === 'pay'
              ? firstPaymentDateBindings.togglePath2
              : firstPaymentDateBindings.togglePath1
          }
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.row}>
        <TextField
          id={`${legType}-leg-loan-payment-day`}
          label="interest-rate-form.field.payment-day"
          name={`${legType}_leg_parameters.payment_day`}
          margin="normal"
          validate={composeValidators(range(0, 31))}
          onChange={
            legType === 'pay'
              ? businessDayBindings.onChange2
              : businessDayBindings.onChange1
          }
        />
        <DoubleCheckbox
          checked={paymentDayBindings.enabled}
          onChange={
            legType === 'pay'
              ? paymentDayBindings.togglePath2
              : paymentDayBindings.togglePath1
          }
        />
      </div>
    </Grid>
  </>
);

LegConventionInputs.propTypes = {
  businessDayBindings: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dayCountBindings: PropTypes.object.isRequired,
  firstPaymentDateBindings: PropTypes.object.isRequired,
  legType: PropTypes.string.isRequired,
  paymentDayBindings: PropTypes.object.isRequired,
};

export const ConventionInputs = () => (
  <>
    <Grid container direction="row" spacing={24}>
      <Grid item xs={12} sm={6} md={6}>
        <Select
          id="loan-business-day-convention"
          label="interest-rate-form.field.business-day-convention"
          name="business_day_convention"
          margin="normal"
          options={BUSINESS_DAY_CONVENTIONS}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Select
          id="loan-day-count-conventions"
          label="interest-rate-form.field.day-count-convention"
          name="day_count"
          margin="normal"
          options={DAY_COUNT_CONVENTIONS}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Select
          id="loan-holiday-calendar"
          label="interest-rate-form.field.holiday-calendar"
          name="holiday_calendar"
          margin="normal"
          options={HOLIDAY_CALENDARS}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={24}>
      <Grid item xs={12} sm={3} md={3}>
        <DatePicker
          id="loan-first-payment-date"
          label="interest-rate-form.field.first-payment-date"
          name="first_payment_date"
          margin="normal"
          format={DEFAULT_DATE_FORMAT}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <TextField
          id="loan-payment-day"
          label="interest-rate-form.field.payment-day"
          name="payment_day"
          margin="normal"
          validate={composeValidators(range(0, 31))}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <TextField
          id="loan-volatility"
          label="interest-rate-form.field.volatility"
          name="volatility"
          margin="normal"
          inputProps={{
            decimalScale: 4,
          }}
          inputComponent={FormikPercentageInput}
        />
      </Grid>
    </Grid>
  </>
);
