import enTranslations from './labels_en.json';
import deTranslations from './labels_de.json';
import testTranslations from './test-labels.json';

export const getTranslations = locale => {
  if (locale === 'en') {
    return enTranslations;
  }
  if (locale === 'de') {
    return deTranslations;
  }
  if (locale === 'test') {
    return testTranslations;
  }
  return {};
};
