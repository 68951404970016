import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageHeader from '../../../components/page-header';
// import DealsList from './DealsList';
import PortfolioList from './PortfolioList';
import PageError from '../../../components/PageError';

import useApiGetRepeat from '../../../utils/useApiGetRepeat';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useStateBoolean from '../../../utils/useStateBoolean';
import timeout from '../../../utils/timeout';
import DealUpload from '../deal-upload/DealUpload';
import Modal from '../../../components/Modal';
import StrategySelector from '../../strategy-manager/StrategySelector';
import { ERRORS_BY_STATUS_CODE } from '../../../constants';
import CurrentUserContext from '../../../CurrentUserContext';
// import BasicPointSensitivityChart from '../../../components/charts/BasicPointSensitivityChart';
import InterestSensitivityByScenarioChart from '../../../components/charts/InterestSensitivityByScenarioChart';
import { AddDealButton } from '../AddDealButton';
import KPIBox from '../../../components/kpis/KPIBox';
import { nFormatter } from '../../../utils/formatters';

function ClientDetailsPage() {
  const [isOpen, openModal, closeModal] = useStateBoolean(false);
  const { setUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { clientId } = useParams();

  const isRegional = searchParams.get('account_group') === 'region';

  function onDealSelect(id) {
    navigate(
      `/clients/${clientId}/deals/${id}${
        isRegional ? '?account_group=region' : ''
      }`,
    );
  }

  function onStrategiesSave(data) {
    navigate(`/clients/${clientId}/strategy-manager`, {
      state: { portfolios: data },
    });
  }

  const {
    state: [deals, areDealsLoading, error],
    repeat: refreshDeals,
  } = useApiGetRepeat(
    `/dealmanager?client_id=${clientId}${
      isRegional ? '&account_group=region' : ''
    }`,
  );
  const {
    state: [portfolios, arePortfoliosLoading],
    repeat: refreshPortfolios,
  } = useApiGetRepeat(`/portfoliomanager?client_id=${clientId}`);
  const {
    state: [reports, reportsLoading],
    repeat: refreshReports,
  } = useApiGetRepeat(
    `/regulatoryreports?client_id=${clientId}${
      isRegional ? '&account_group=region' : ''
    }`,
  );

  const [isAwaitingMenuActionResult, setIsAwaitingMenuActionResult] =
    useState(false);
  const onAsyncActionClick = async () => {
    setIsAwaitingMenuActionResult(true);
    await timeout(3000);
    window.location.reload();
  };

  if (
    areDealsLoading ||
    arePortfoliosLoading ||
    reportsLoading ||
    isAwaitingMenuActionResult
  ) {
    return <LoadingSpinner />;
  }

  if (error) {
    const errorCode = error.response.status;
    const msg = ERRORS_BY_STATUS_CODE[errorCode] || 'Unknown error.';
    setUser(null);
    return <PageError customMessage={msg} redirectToHome />;
  }

  const handleCloseModal = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    closeModal();
  };

  function onDealUploadFinish() {
    closeModal();
    refreshDeals();
    refreshPortfolios();
    refreshReports();
  }

  function handleInstrumentRedirect(instrumentType) {
    navigate(`/clients/${clientId}/create-deal/${instrumentType}`);
  }

  const {
    // par_rate_sensitivity_by_tenor_buckets,
    npv_difference_by_deal_and_scenario,
  } = reports;

  const getKPIItems = function () {
    const metrics = deals.reduce(
      (totals, deal) => ({
        totalNominalBalance: deal.nominal_balance + totals.totalNominalBalance,
        totalNpv: deal.npv + totals.totalNpv,
      }),
      {
        totalNominalBalance: 0,
        totalNpv: 0,
      },
    );

    const [nominalValue, nominalSuffix] = nFormatter(
      metrics.totalNominalBalance,
      2,
    );
    const [npvValue, npvSuffix] = nFormatter(metrics.totalNpv, 2);

    return [
      {
        value: deals.length,
        label: 'client-details.metrics.deals',
      },
      {
        value: nominalValue,
        suffix: nominalSuffix,
        label: 'client-details.metrics.notional',
      },
      {
        value: npvValue,
        suffix: npvSuffix,
        label: 'client-details.metrics.npv',
      },
    ];
  };

  const kpiItems = getKPIItems();

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            title: <FormattedMessage id="client-manager.page-title" />,
            path: '/clients',
          },
          {
            title: clientId,
            path: `/clients/${clientId}`,
          },
        ]}
      >
        <StrategySelector
          portfolios={portfolios || []}
          onStrategiesSave={onStrategiesSave}
        />
      </PageHeader>
      <KPIBox title="client-details.metrics.title" items={kpiItems} />
      <PortfolioList
        deals={deals || []}
        isLoading={areDealsLoading}
        isRegional={isRegional}
        onDealSelect={onDealSelect}
        clientId={clientId}
        onAsyncActionClick={onAsyncActionClick}
        actions={
          <AddDealButton
            handleInstrumentRedirect={handleInstrumentRedirect}
            openModal={openModal}
          />
        }
      />
      {/* <BasicPointSensitivityChart
        data={par_rate_sensitivity_by_tenor_buckets}
      /> */}
      <InterestSensitivityByScenarioChart
        type="interest"
        data={npv_difference_by_deal_and_scenario}
      />
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <DealUpload onFinish={onDealUploadFinish} />
      </Modal>
    </>
  );
}

export default ClientDetailsPage;
