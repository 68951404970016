import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DateRange from '@mui/icons-material/DateRange';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CurrentUserContext from '../CurrentUserContext';
import DateDisplay from '../components/DateDisplay';
import { ReactComponent as EnLanguage } from '../images/language/lang-en.svg';
import { ReactComponent as DeLanguage } from '../images/language/lang-de.svg';
import { I18nContext } from '../I18nContext';
import api from '../utils/api';
import { LOGIN_URL } from '../constants';

function Header() {
  const { user, setUser } = useContext(CurrentUserContext);
  const { locale, setLocale } = useContext(I18nContext);

  const logOut = () => {
    api.post('/oauth/logout', null);
    setUser(null);
    // window.location.href = window.location.origin;
    window.location.href = LOGIN_URL;
  };

  const toggleLocale = () => {
    if (locale === 'en') {
      setLocale('de');
    } else {
      setLocale('en');
    }
  };

  return (
    <header className="header">
      <div className="header__bar">
        <Typography color="inherit" style={{ fontSize: 13, opacity: 0.5 }}>
          <FormattedMessage
            id="page.header.report-date"
            values={{ date: <DateDisplay value={new Date()} /> }}
          />
        </Typography>
        <DateRange style={{ marginLeft: 10 }} />
      </div>
      <div className="header__bar">
        <Typography
          color="inherit"
          style={{
            fontSize: 13,
            fontWeight: 500,
          }}
        >
          {user && `${user.name}`}
        </Typography>
        <Tooltip
          title={<FormattedMessage id="page.header.log-out" />}
          aria-label="Log out"
        >
          <IconButton
            aria-owns="material-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={logOut}
          >
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <IconButton
          aria-owns="material-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={toggleLocale}
        >
          <Language />
        </IconButton>
      </div>
    </header>
  );
}

const Language = () => {
  const intl = useIntl();
  if (intl.locale === 'de') {
    return <DeLanguage />;
  }
  return <EnLanguage />;
};

export default Header;
