export function nFormatter(num, digits) {
  const units = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];

  let decimal;

  for (let i = units.length - 1; i >= 0; i--) {
    // eslint-disable-next-line no-restricted-properties
    decimal = Math.pow(1000, i + 1);

    if (num <= -decimal || num >= decimal) {
      return [+(num / decimal).toFixed(digits), units[i]];
    }
  }

  return [num.toFixed(digits), ''];
}

export function numberWithSpaces(x) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}
