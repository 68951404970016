import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import maxDate from 'date-fns/max';
import classNames from 'classnames';
import lodashOrderBy from 'lodash.orderby';
import Table from '../../../components/table';
import TableRow from '../../../components/table/TableRow';
import ActionBar from '../../../layout/ActionBar';
import ContentBox from '../../../layout/ContentBox';
import useStateToggle from '../../../utils/useStateToggle';
import DateDisplay from '../../../components/DateDisplay';
import { PortfolioMenu, DealMenu } from './Menus';
import { ReactComponent as DropArrow } from '../../../images/icons/DropArrow.svg';
import { ReactComponent as DropExpanded } from '../../../images/icons/DropExpanded.svg';

// import './portfolio-list.scss';
import parseServerDateTime from '../../../utils/parseServerDateTime';
import useSorting from '../../../utils/useSorting';
import { numberWithSpaces } from '../../../utils/formatters';

PortfolioList.propTypes = {
  deals: PropTypes.array,
  isLoading: PropTypes.bool,
  onDealSelect: PropTypes.func,
  onAsyncActionClick: PropTypes.func.isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function PortfolioList({
  deals,
  isLoading,
  onDealSelect,
  onAsyncActionClick,
  actions,
}) {
  const orderedPortfolios = useMemo(() => {
    const grouped = groupByPortfolioName(deals);
    return lodashOrderBy(grouped, [], []);
  }, [deals]);

  const tableConfig = {
    idField: 'portfolio_name',
    cols: [
      {
        title: 'client-details.table.deals.deal-index',
        width: 20,
        value: (row, index) => (
          <span className="portfolio-list__index">{`${index + 1}.`}</span>
        ),
        align: 'left',
      },
      {
        title: '',
        width: '5px',
        key: 'expandToggle',
        value: row => {
          if (row.deals.length <= 1) {
            return null;
          }
          return (
            <span className="portfolio-list__expand-toggle">
              {row.isExpanded ? <DropArrow /> : <DropExpanded />}
            </span>
          );
        },
      },
      {
        title: 'client-details.table.deals.portfolio',
        key: 'portfolioName',
        sortable: true,
        sortableColumn: 'portfolio_name',
        width: '40%',
        value: row => {
          const className = classNames('portfolio-list__portfolio', {
            'portfolio-list__portfolio-multi-deals': row.deals.length > 1,
          });
          return <span className={className}>{row.portfolio_name}</span>;
        },
        align: 'left',
      },
      {
        title: 'client-details.table.deals.deals',
        width: '5px',
        key: 'deals',
        sortable: false,
        value: row => row.deals && row.deals.length,
        align: 'left',
      },
      {
        title: 'client-details.table.deals.maturity',
        key: 'maturityDate',
        sortable: false,
        value: row => row.amount,
        align: 'right',
      },
      {
        title: 'client-details.table.deals.notional',
        key: 'notional',
        sortable: false,
        value: row => row.amount,
        align: 'right',
      },
      {
        title: 'client-details.table.deals.npv',
        key: 'npv',
        numeric: true,
        sortable: false,
        value: row => row.amount,
        align: 'right',
      },
      {
        title: 'client-details.table.deals.last-modified',
        key: 'lastModified',
        sortable: true,
        sortableColumn: 'modified_utc',
        value: row => <DateDisplay value={row.modified_utc} />,
        align: 'right',
      },
      {
        title: '',
        // eslint-disable-next-line
        value: (row, index) => (
          <PortfolioMenu
            redirects={orderedPortfolios.length === 1}
            portfolio={row}
          />
        ),
        align: 'right',
      },
    ],
  };

  const [orderedList, orderBy, order, onRequestSort] = useSorting(
    orderedPortfolios,
    'portfolioName',
    tableConfig.cols,
    false,
  );

  const counterPartyDealExists = orderedList.some(
    deal => !!deal.counterparty_name,
  );

  return (
    <ContentBox>
      <ActionBar
        title={
          <FormattedMessage
            id="client-details.table.deals.title"
            values={{ amount: orderedList && orderedList.length }}
          />
        }
      >
        {actions}
      </ActionBar>
      <Table
        className="portfolio-list"
        onRequestSort={onRequestSort}
        showPlaceholder
        config={tableConfig}
        order={order}
        orderBy={orderBy}
        data={orderedList}
        onRowClick={onDealSelect}
        loading={isLoading}
        onAsyncActionClick={onAsyncActionClick}
        RowComponent={ExpandCollapseRow}
      />
      {counterPartyDealExists && (
        <p className="text-italic">
          <FormattedMessage id="client-details.table.footer.counterparty-note" />
        </p>
      )}
    </ContentBox>
  );
}

function groupByPortfolioName(portfolios) {
  if (!portfolios) {
    return portfolios;
  }

  return portfolios.reduce((items, deal) => {
    const existingPortfolio = items.find(
      i => i.portfolio_name === deal.portfolio_name,
    );

    const dealToAdd = {
      ...deal,
      modified_utc: deal.modified_utc
        ? parseServerDateTime(deal.modified_utc)
        : null,
    };
    if (existingPortfolio) {
      existingPortfolio.deals = [...existingPortfolio.deals, dealToAdd];

      existingPortfolio.modified_utc = maxDate(
        existingPortfolio.deals.map(d => d.modified_utc),
      );
      return items;
    }
    return [
      ...items,
      {
        portfolio_name: dealToAdd.portfolio_name,
        modified_utc: dealToAdd.modified_utc,
        deals: [dealToAdd],
      },
    ];
  }, []);
}

const ExpandCollapseRow = React.memo(
  ({ row, onRowClick, onAsyncActionClick, ...props }) => {
    const [isExpanded, toggleExpand] = useStateToggle(true);

    function handlePortfolioRowClick(id, clickedRow, event) {
      const isNoninteractivePart =
        event.target.nodeName !== 'TR' &&
        event.target.nodeName !== 'TD' &&
        event.target.className.baseVal !== 'drop' &&
        event.target.nodeName !== 'SPAN';
      if (isNoninteractivePart) return;
      if (clickedRow.deals.length > 1) {
        toggleExpand();
      } else {
        const clickedDeal = clickedRow.deals[0].id;
        onRowClick(clickedDeal);
      }
    }
    function handleDealRowClick(id, clickedRow, event) {
      const isNoninteractivePart =
        event.target.nodeName !== 'TR' &&
        event.target.nodeName !== 'TD' &&
        event.target.className.baseVal !== 'drop' &&
        event.target.nodeName !== 'SPAN';
      if (isNoninteractivePart) return;
      const clickedDeal = clickedRow.id;
      onRowClick(clickedDeal);
    }

    const dealsColumns = {
      idField: 'id',
      cols: [
        {
          title: '#',
          value: () => '',
        },
        {
          title: '',
          key: 'expandToggle',
          value: () => '',
        },
        {
          title: 'Deal name',
          // eslint-disable-next-line
        value: row => (
            <span className="portfolio-list__deal">
              {row.deal_name}
              {!!row.counterparty_name && <span> *</span>}
            </span>
          ),
          align: 'left',
        },
        {
          title: 'Deals',
          value: () => '',
        },
        {
          title: 'Maturity',
          // eslint-disable-next-line
        value: row => <DateDisplay value={row.maturity_date} />,
          align: 'right',
        },
        {
          title: 'Notional',
          // eslint-disable-next-line
        value: row => numberWithSpaces(row.nominal_balance.toFixed(2)),
          align: 'right',
        },
        {
          title: 'NPV',
          // eslint-disable-next-line
        value: row => numberWithSpaces(row.npv.toFixed(2)),
          align: 'right',
        },
        {
          title: 'Last modified',
          // eslint-disable-next-line
        value: row => <DateDisplay value={row.modified_utc} />,
          align: 'right',
        },
        {
          title: '',
          // eslint-disable-next-line
        value: row => (
            <DealMenu
              deal={row}
              handleOpen={() => onRowClick(row.id)}
              handleAsyncAction={onAsyncActionClick}
              isDerivative={row.instrument_type_group === 'DERIVATIVE'}
            />
          ),
          align: 'right',
        },
      ],
    };

    return (
      <>
        <TableRow
          key={row.portfolio_name}
          row={{ ...row, isExpanded }}
          onRowClick={handlePortfolioRowClick}
          {...props}
        />
        {isExpanded &&
          row.deals.map(d => (
            <TableRow
              key={d.id}
              row={d}
              onRowClick={handleDealRowClick}
              config={dealsColumns}
            />
          ))}
      </>
    );
  },
);

ExpandCollapseRow.propTypes = {
  row: PropTypes.object,
  onRowClick: PropTypes.func,
  asyncActionClicked: PropTypes.func,
};

export default PortfolioList;
