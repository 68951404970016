import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import {
  DatePicker,
  Select,
  TextField,
} from '../../../../components/forms/formik';
import { AMORTIZATION_FREQUENCIES } from '../../utils/lookup-values';
import DoubleCheckbox from '../../../../components/forms/DoubleCheckbox';
import FormikMoneyAmountInput from '../../../../components/forms/formik/input-types/FormikMoneyAmountInput';
import FormikPercentageInput from '../../../../components/forms/formik/input-types/FormikPercentageInput';
import { DEFAULT_DATE_FORMAT } from '../../../../constants';

export const LegAmortizationInputs = ({
  amountBindings,
  classes,
  disabled,
  frequencyBindings,
  legType,
  percentBindings,
  startDateBindings,
}) => (
  <>
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.row}>
        <Select
          id={`${legType}-leg-loan-amortization-frequency`}
          label="interest-rate-form.field.amort-freq"
          name={`${legType}_leg_parameters.amortization.amortization_frequency`}
          margin="normal"
          options={AMORTIZATION_FREQUENCIES}
          onChange={
            legType === 'pay'
              ? frequencyBindings.onChange2
              : frequencyBindings.onChange1
          }
        />
        <DoubleCheckbox
          checked={frequencyBindings.enabled}
          onChange={
            legType === 'pay'
              ? frequencyBindings.togglePath2
              : frequencyBindings.togglePath1
          }
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.row}>
        <TextField
          id={`${legType}-leg-loan-amortization-amount`}
          label="interest-rate-form.field.amort-amount"
          name={`${legType}_leg_parameters.amortization.amortization_amount`}
          inputComponent={FormikMoneyAmountInput}
          margin="normal"
          disabled={disabled}
          onChange={
            legType === 'pay'
              ? amountBindings.onChange2
              : amountBindings.onChange1
          }
        />
        <DoubleCheckbox
          checked={amountBindings.enabled}
          onChange={
            legType === 'pay'
              ? amountBindings.togglePath2
              : amountBindings.togglePath1
          }
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.row}>
        <TextField
          id={`${legType}-leg-loan-amortization-amount-percent`}
          label="interest-rate-form.field.amort-amount-percent"
          name={`${legType}_leg_parameters.amortization.amortization_percent`}
          margin="normal"
          disabled={disabled}
          inputComponent={FormikPercentageInput}
          onChange={
            legType === 'pay'
              ? percentBindings.onChange2
              : percentBindings.onChange1
          }
        />
        <DoubleCheckbox
          checked={percentBindings.enabled}
          onChange={
            legType === 'pay'
              ? percentBindings.togglePath2
              : percentBindings.togglePath1
          }
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.row}>
        <DatePicker
          id={`${legType}-leg-loan-amortization-start-date`}
          label="interest-rate-form.field.amort-start-date"
          name={`${legType}_leg_parameters.amortization.amortization_start_date`}
          margin="normal"
          disabled={disabled}
          onChange={
            legType === 'pay'
              ? startDateBindings.onChange2
              : startDateBindings.onChange1
          }
          format={DEFAULT_DATE_FORMAT}
        />
        <DoubleCheckbox
          checked={startDateBindings.enabled}
          onChange={
            legType === 'pay'
              ? startDateBindings.togglePath2
              : startDateBindings.togglePath1
          }
        />
      </div>
    </Grid>
  </>
);

LegAmortizationInputs.propTypes = {
  amountBindings: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  frequencyBindings: PropTypes.object.isRequired,
  legType: PropTypes.string.isRequired,
  percentBindings: PropTypes.object.isRequired,
  startDateBindings: PropTypes.object.isRequired,
};

export const AmortizationInputs = ({ disabled }) => (
  <Grid container direction="row" spacing={24}>
    <Grid item xs={12} sm={3} md={3}>
      <Select
        id="loan-amortization-frequency"
        label="interest-rate-form.field.amort-freq"
        name="amortization.amortization_frequency"
        margin="normal"
        options={AMORTIZATION_FREQUENCIES}
      />
    </Grid>
    <Grid item xs={12} sm={3} md={3}>
      <TextField
        disabled={disabled}
        id="loan-amortization-amount"
        label="interest-rate-form.field.amort-amount"
        name="amortization.amortization_amount"
        inputComponent={FormikMoneyAmountInput}
        margin="normal"
      />
    </Grid>
    <Grid item xs={12} sm={3} md={3}>
      <TextField
        disabled={disabled}
        id="loan-amortization-amount-percent"
        label="interest-rate-form.field.amort-amount-percent"
        name="amortization.amortization_percent"
        margin="normal"
        inputComponent={FormikPercentageInput}
      />
    </Grid>
    <Grid item xs={12} sm={3} md={3}>
      <DatePicker
        disabled={disabled}
        id="loan-amortization-start-date"
        label="interest-rate-form.field.amort-start-date"
        name="amortization.amortization_start_date"
        margin="normal"
        format={DEFAULT_DATE_FORMAT}
      />
    </Grid>
  </Grid>
);

AmortizationInputs.propTypes = {
  disabled: PropTypes.bool,
};
