import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CurrentUserContext from '../../CurrentUserContext';
import logo from '../../images/logo-negative.png';
import CenterContainer from '../../components/CenterContainer';
import LoadingSpinner from '../../components/LoadingSpinner';
import api from '../../utils/api';
import './login-page.scss';
import LoginError from './LoginError';

const LoginOAuthCallbackPage = () => {
  const [hasError, setHasError] = useState(false);
  const { setUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idToken = searchParams.get('id_token');
  const loginWithJwtUrl = '/oauth/loginwithjwt';

  const login = () => {
    api
      .postwithbearertoken(loginWithJwtUrl, null, idToken)
      .then(user => {
        setUser(user);
        navigate('/clients');
      })
      .catch(e => {
        setHasError(true);
        console.log(e);
      });
  };

  useEffect(() => {
    if (!idToken) {
      setHasError(true);
      return;
    }
    login();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <CenterContainer className="login-page">
      <div className="login-page__logo">
        <img src={logo} alt="logo" />
      </div>
      {hasError ? (
        <LoginError />
      ) : (
        <LoadingSpinner label="login.processing-login" />
      )}
    </CenterContainer>
  );
};

export default LoginOAuthCallbackPage;
