export default function collectArrayFieldsToObjects(object, mapping) {
  const payments = [];
  if (object) {
    const maxItems = Object.values(object)
      .filter(v => v)
      .reduce(
        (maxLength, currentValue) => Math.max(maxLength, currentValue.length),
        0,
      );

    for (let i = 0; i < maxItems; i++) {
      const get = getByIndex(i);
      const collectedItem = {};
      Object.entries(mapping).forEach(([sourceField, targetField]) => {
        collectedItem[targetField] = get(object[sourceField]);
      });

      payments.push(collectedItem);
    }
  }
  return payments;
}

function getByIndex(index) {
  return function get(array) {
    if (array && array.length > index) {
      return array[index];
    }
    return null;
  };
}
