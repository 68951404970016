/* eslint-disable prefer-const */
import React, { useState, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LoanForm from './LoanForm';
import PageHeader from '../../../components/page-header';
import DealScheduleList from '../deal-details/DealScheduleList';
import api from '../../../utils/api';
import InstrumentType from '../utils/InstrumentType';
import { parseClients, parsePortfolios } from '../utils/parseRequestInfo';
import PayerSwapScheduleList from '../deal-details/PayerSwapScheduleList';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ContentBox from '../../../layout/ContentBox';
import InterestRateDealForm from './InterestRateDealForm';
import PageError from '../../../components/PageError';
import useApiGetRepeat from '../../../utils/useApiGetRepeat';
import { ERRORS_BY_STATUS_CODE } from '../../../constants';
import CurrentUserContext from '../../../CurrentUserContext';
import '../client-loan.scss';
import { CapFloorForm } from './CapFloorDealForm';
import KPIBox from '../../../components/kpis/KPIBox';
import { nFormatter } from '../../../utils/formatters';
import PercentDisplay from '../../../components/PercentDisplay';

const DEAL_TYPE_URLS = {
  [InstrumentType.FIXED_RATE]: 'fixedrateloans',
  [InstrumentType.FLOATING_RATE]: 'floatingrateloans',
  [InstrumentType.INTEREST_RATE]: 'interestrateswaps',
  [InstrumentType.CAP_FLOOR]: 'capfloors',
};

const FORM_FOOTER_TITLE = 'client-loan.footer.title';
const FORM_FOOTER_DESCRIPTION = 'client-loan.footer.description';

function ClientPortfolioPage() {
  const { dealId, clientId } = useParams();
  const [currentClientId, setCurrentClientId] = useState(clientId);
  const [addedPortfolios, setAddedPortfolios] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isRegional = searchParams.get('account_group') === 'region';

  const {
    state: [clients, isClientsLoading, clientsError],
    setResult: setClients,
  } = useApiGetRepeat('/clientmanager');

  const {
    state: [portfolios, , portfoliosError],
  } = useApiGetRepeat(`/portfoliomanager?client_id=${currentClientId}`);

  const parsedPortfolios = parsePortfolios(portfolios);
  const parsedClients = parseClients(clients);

  const { setUser } = useContext(CurrentUserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    state: [deal, isLoading],
    repeat: repeatDealGet,
  } = useApiGetRepeat(
    `/deals/${dealId}${isRegional ? '?account_group=region' : ''}`,
  );

  const [formErrors, setFormErrors] = useState(null);

  if (isLoading || isClientsLoading || isSubmitting) {
    return <LoadingSpinner />;
  }

  const error = clientsError || portfoliosError;
  if (error) {
    const msg =
      ERRORS_BY_STATUS_CODE[error.response.status] || 'Unknown error.';
    setUser(null);
    return <PageError customMessage={msg} redirectToHome />;
  }

  function addPortfolio(portfolio) {
    setAddedPortfolios([...addedPortfolios, portfolio]);
  }

  function addClient(client) {
    setClients([...parsedClients, client]);
  }

  async function onUpdateLoan(values, { resetForm }) {
    try {
      await api.put(
        `/${DEAL_TYPE_URLS[deal.instrument_type]}/${dealId}`,
        values,
      );
      resetForm(values);
      if (currentClientId !== clientId) {
        navigate(`/clients/${currentClientId}/deals/${dealId}`);
      } else {
        repeatDealGet();
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('e', e);
      if (e.response && e.response.data) {
        parseValidationErrors(e.response.data.Errors);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const parseValidationErrors = errorData => {
    const parsedErrors = {};
    errorData.forEach(apiError => {
      parsedErrors[apiError.Source] = apiError.Message;
    });
    setFormErrors(parsedErrors);
  };

  const dealName = deal && deal.parameters.deal_name;
  const analytics = deal && deal.analytics;

  const getFormTag = instrumentType => {
    if (InstrumentType.INTEREST_RATE === instrumentType) {
      return InterestRateDealForm;
    }
    return InstrumentType.CAP_FLOOR === instrumentType
      ? CapFloorForm
      : LoanForm;
  };

  const FormTag = getFormTag(deal.instrument_type);

  const getKPIItems = function () {
    if (
      [InstrumentType.FLOATING_RATE, InstrumentType.FIXED_RATE].includes(
        deal.instrument_type,
      )
    ) {
      const [currentNominal, currentNominalSuffix] = nFormatter(
        analytics.nominal_balance,
        2,
      );
      const [accruedInterest, accruedInterestSuffix] = nFormatter(
        analytics.accrued_interest,
        2,
      );

      return [
        {
          value: currentNominal,
          suffix: currentNominalSuffix,
          label: 'client-loan.metrics.current-nominal',
        },
        {
          value: analytics.weighted_average_maturity_esma,
          suffix: 'Y',
          label: 'client-loan.metrics.interest-reset',
        },
        {
          value: accruedInterest,
          suffix: accruedInterestSuffix,
          label: 'client-loan.metrics.accrued-interest',
        },
      ];
    }

    const [npvValue, npvSuffix] = nFormatter(analytics.npv, 2);
    const [basisPointValue, basisPointSuffix] = nFormatter(
      analytics.basis_point_value,
      2,
    );

    return [
      {
        value: npvValue,
        suffix: npvSuffix,
        label: 'client-loan.metrics.npv',
      },
      {
        value: (
          <PercentDisplay
            hideSuffix
            value={analytics.npv_in_percent}
            decimalScale={2}
          />
        ),
        suffix: '%',
        label: 'client-loan.metrics.npv-percent',
      },
      {
        value: basisPointValue,
        suffix: basisPointSuffix,
        label: 'client-loan.metrics.basis-point',
      },
    ];
  };

  const kpiItems = getKPIItems();

  return (
    <div className="client-loan">
      <PageHeader
        breadcrumbs={[
          {
            title: <FormattedMessage id="client-manager.page-title" />,
            path: '/clients',
          },
          {
            title: clientId,
            path: `/clients/${clientId}${
              isRegional ? '?account_group=region' : ''
            }`,
          },
          {
            title: dealName,
            path: `/clients/${clientId}/deals/${dealId}`,
          },
        ]}
      />
      <KPIBox title="client-loan.metrics.title" items={kpiItems} />
      <FormTag
        clients={parsedClients}
        onSubmit={onUpdateLoan}
        portfolios={[...addedPortfolios, ...parsedPortfolios]}
        initialValues={deal.parameters}
        instrumentType={deal.instrument_type}
        footerTitle={FORM_FOOTER_TITLE}
        footerDescription={FORM_FOOTER_DESCRIPTION}
        onCreateClient={client => addClient(client)}
        onCreatePortfolio={portfolio => addPortfolio(portfolio)}
        onClientChange={newClientId => setCurrentClientId(newClientId)}
        formErrors={formErrors}
      />
      <ContentBox>
        {deal.instrument_type !== InstrumentType.INTEREST_RATE && (
          <DealScheduleList
            instrumentType={deal.instrument_type}
            schedule={deal ? deal.schedule : null}
            loading={isLoading}
          />
        )}
        {deal.instrument_type === InstrumentType.INTEREST_RATE && (
          <PayerSwapScheduleList
            schedule={deal ? deal.schedule : null}
            loading={isLoading}
          />
        )}
      </ContentBox>
    </div>
  );
}

export default ClientPortfolioPage;
