import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import TablePropTypes from './TablePropTypes';

TableHeader.propTypes = {
  classes: PropTypes.object,
  config: TablePropTypes.config,
  onRequestSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

const styles = () => ({
  headRow: {
    height: 32,
  },
});

function TableHeader({
  config: { cols },
  order,
  orderBy,
  onRequestSort,
  classes,
}) {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  function renderHeadingColumns() {
    return cols.map(col => {
      const colKey = getColKey(col);
      const content = (
        <div className="generic-table__col-header">
          {col.title ? <FormattedMessage id={col.title} /> : ''}
        </div>
      );

      if (col.sortable && onRequestSort) {
        return (
          <TableCell
            key={colKey}
            align={col.align || 'right'}
            sortDirection={orderBy === colKey ? order : false}
          >
            <TableSortLabel
              active={orderBy === colKey}
              direction={order}
              onClick={createSortHandler(colKey)}
            >
              {content}
            </TableSortLabel>
          </TableCell>
        );
      }

      const style = {};
      if (col.width) {
        style.width = col.width;
      }

      return (
        <TableCell align={col.align || 'right'} key={colKey} style={style}>
          {content}
        </TableCell>
      );
    });
  }
  return (
    <TableHead>
      <TableRow className={classes.headRow}>{renderHeadingColumns()}</TableRow>
    </TableHead>
  );
}

function getColKey(col) {
  return col.key ? col.key : col.title;
}

export default withStyles(styles)(TableHeader);
