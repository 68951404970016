export const CURRENCIES = [
  {
    value: 'usd',
    text: 'USD',
  },
  {
    value: 'eur',
    text: 'EUR',
  },
  {
    value: 'chf',
    text: 'CHF',
  },
  {
    value: 'jpy',
    text: 'JPY',
  },
  {
    value: 'gbp',
    text: 'GBP',
  },
];

export const PAYMENT_FREQUENCIES = [
  {
    value: '1m',
    label: 'select-option.payment-frequency.monthly',
  },
  {
    value: '3m',
    label: 'select-option.payment-frequency.quarterly',
  },
  {
    value: '6m',
    label: 'select-option.payment-frequency.semi-annual',
  },
  {
    value: '1y',
    label: 'select-option.payment-frequency.annual',
  },
];

export const ASSET_LIABILITIES = [
  {
    value: 'liability',
    label: 'select-option.asset-liability.liability',
  },
  {
    value: 'asset',
    label: 'select-option.asset-liability.asset',
  },
];

export const LegType = {
  FIXED: 'fixed',
  FLOATING: 'floating',
};

export const LEG_TYPES = [
  {
    value: LegType.FIXED,
    label: 'select-option.leg-type.fixed',
  },
  {
    value: LegType.FLOATING,
    label: 'select-option.leg-type.floating',
  },
];

const CapFloorDirection = {
  ASSET: 'asset',
  LIABILITY: 'liability',
};

export const CAP_FLOOR_DIRECTIONS = [
  {
    value: CapFloorDirection.ASSET,
    label: 'select-option.direction.buy',
  },
  {
    value: CapFloorDirection.LIABILITY,
    label: 'select-option.direction.sell',
  },
];

export const CapFlorType = {
  CAP: 'cap',
  FLOOR: 'floor',
};

export const CAP_FLOOR_TYPES = [
  {
    value: CapFlorType.CAP,
    label: 'select-option.cap-floor-type.cap',
  },
  {
    value: CapFlorType.FLOOR,
    label: 'select-option.cap-floor-type.floor',
  },
];

export const BUSINESS_DAY_CONVENTIONS = [
  {
    value: 'no_adjustment',
    label: 'select-option.business-day-convention.none',
  },
  {
    value: 'modified_following',
    label: 'select-option.business-day-convention.mod-following',
  },
  {
    value: 'next_workday',
    label: 'select-option.business-day-convention.next-workday',
  },
  {
    value: 'previous_workday',
    label: 'select-option.business-day-convention.prev-workday',
  },
];

export const AMORTIZATION_FREQUENCIES = [
  {
    value: 'no_amortization',
    label: 'select-option.amort-freq.none',
  },
  {
    value: 'use_payment_frequency',
    label: 'select-option.amort-freq.payment-freq',
  },
  {
    value: '1m',
    label: 'select-option.amort-freq.monthly',
  },
  {
    value: '3m',
    label: 'select-option.amort-freq.quarterly',
  },
  {
    value: '6m',
    label: 'select-option.amort-freq.semi-annual',
  },
  {
    value: '1y',
    label: 'select-option.amort-freq.annual',
  },
];

export const HOLIDAY_CALENDARS = [
  {
    value: 'euro_target',
    text: 'Euro Target',
  },
  {
    value: 'deutsche_bankfeiertage',
    text: 'Deutsche Bankfeiertage',
  },
  {
    value: 'london',
    text: 'London',
  },
  {
    value: 'new_york',
    text: 'New York',
  },
  {
    value: 'zurich',
    text: 'Zurich',
  },
];

export const DAY_COUNT_CONVENTIONS = [
  {
    value: '30_360',
    text: '30/360',
  },
  {
    value: '30_360_bond',
    text: '30/360B',
  },
  {
    value: '30_360_german',
    text: '30/360S',
  },
  {
    value: '365_360',
    text: '365/360',
  },
  {
    value: 'act_360',
    text: 'Act/360',
  },
  {
    value: 'act_365',
    text: 'Act/365',
  },
  {
    value: 'act_act_afb',
    text: 'Act/Act (AFB)',
  },
  {
    value: 'act_act_isda',
    text: 'Act/Act (ISDA)',
  },
];
