import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../components/button';
import { ReactComponent as Attention } from '../images/icons/Attention.svg';

export default function NotFoundPage() {
  return (
    <div className="center-both-ways">
      <div>
        <Attention />
        <p>
          <FormattedMessage id="common.not-found.page-title" />
        </p>
        <Button buttonType="outlined" size="s" to="/clients" uppercase>
          common.button.home
        </Button>
      </div>
    </div>
  );
}
