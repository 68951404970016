import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Table from '../../components/table';
import ContentBox from '../../layout/ContentBox';
import ActionBar from '../../layout/ActionBar';
import { numberWithSpaces } from '../../utils/formatters';

const config = {
  idField: 'tenor_bucket',
  cols: [
    {
      key: 'tenor_bucket',
      title: '',
      value: 'tenor_bucket',
      align: 'left',
    },
    {
      key: 'nr_of_deals',
      title: 'dashboard.table.nominal.deals',
      value: 'nr_of_deals',
      align: 'right',
    },
    {
      key: 'nominal_balance',
      title: 'dashboard.table.nominal.nominal',
      numeric: true,
      value: row => numberWithSpaces(row.nominal_balance.toFixed(2)),
      align: 'right',
    },
    {
      key: 'nominal_balance_percent ',
      title: 'dashboard.table.nominal.nominal-percentage',
      value: row => (row.nominal_balance_percent * 100).toFixed(1) + '%',
      align: 'right',
    },
  ],
};

function Nominal({ data }) {
  const tableData = data.rows.concat(data.total);

  return (
    <ContentBox>
      <ActionBar
        title={
          <>
            <span>
              <FormattedMessage id="dashboard.table.nominal.title" />
            </span>
            {/*
            <IconButton
              aria-label="More"
              aria-owns="some menu"
              aria-haspopup="true"
              onClick={() => {}}
            >
              <MoreHorizIcon />
            </IconButton>
        */}
          </>
        }
        spreadWide
      />
      <Table showPlaceholder config={config} data={tableData} />
    </ContentBox>
  );
}

export default Nominal;

Nominal.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        nominal_balance: PropTypes.number,
        nominal_balance_percent: PropTypes.number,
        nr_of_deals: PropTypes.number,
        tenor_bucket: PropTypes.string,
      }),
    ),
    total: PropTypes.shape({
      nominal_balance: PropTypes.number,
      nominal_balance_percent: PropTypes.number,
      nr_of_deals: PropTypes.number,
      tenor_bucket: PropTypes.string,
    }),
  }),
};

Nominal.defaultProps = {
  data: {
    rows: [],
    total: {
      nominal_balance: 0,
      nominal_balance_percent: 0,
      nr_of_deals: 0,
      tenor_bucket: 'total',
    },
  },
};
