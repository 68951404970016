import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

export default function CenterContainer({ children, className }) {
  return (
    <div className={className}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={10} md={4}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}

CenterContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};
