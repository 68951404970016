import React from 'react';
import SideNavItem from './SideNavItem';
import { ReactComponent as Logo } from '../images/logo-negative-white.svg';
import { ReactComponent as Dashboard } from '../images/icons/Dashboard.svg';
import { ReactComponent as Clients } from '../images/icons/Clients.svg';
import { ReactComponent as LineGraph } from '../images/icons/Line-Graph.svg';

export default function SideNav() {
  return (
    <nav className="sidenav">
      <div>
        <div className="sidenav__logo">
          <span className="sidenav__logo__align-helper" />
          <span>
            <Logo />
          </span>
        </div>
        <SideNavItem
          Icon={Dashboard}
          title="menu.page.dashboard"
          to="/risk-dashboard"
        />
        <SideNavItem
          Icon={Clients}
          title="menu.page.client-manager"
          to="/clients"
        />
        <SideNavItem
          Icon={LineGraph}
          title="menu.page.threshold-monitoring"
          to="/threshold-monitoring"
        />
      </div>
      <div>
        <SideNavItem title="menu.page.release-notes" to="/release-notes" />
      </div>
    </nav>
  );
}
