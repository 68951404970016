export default function validateOnSubmitOnly(onSubmit, validate) {
  return function submitIfValid(values, actions) {
    const { setSubmitting, setErrors } = actions;
    const errors = validate(values);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
    } else {
      onSubmit(values, actions);
    }
  };
}
