import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Table from '../../components/table';
import Button from '../../components/button';
import DateDisplay from '../../components/DateDisplay';
import ContentBox from '../../layout/ContentBox';
import ActionBar from '../../layout/ActionBar';
import { ClientMenu } from './client-details/Menus';
import useSorting from '../../utils/useSorting';
import { numberWithSpaces } from '../../utils/formatters';

ClientList.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  clients: PropTypes.array,
  isLoading: PropTypes.bool,
  isRegional: PropTypes.bool,
  onClientSelect: PropTypes.func,
};

function ClientList({
  actions,
  clients,
  isLoading,
  isRegional,
  onClientSelect,
}) {
  const config = {
    idField: 'client_id',
    cols: [
      {
        key: 'name',
        sortable: true,
        sortableColumn: 'client_id',
        title: 'client-manager.table.clients.name',
        value: row => (
          <Link
            to={`/clients/${encodeURIComponent(row.client_id)}${
              isRegional ? '?account_group=region' : ''
            }`}
          >
            {row.client_id}
          </Link>
        ),
        align: 'left',
      },
      {
        key: 'deals',
        sortable: true,
        sortableColumn: 'nr_of_deals',
        title: 'client-manager.table.clients.deals',
        value: 'nr_of_deals',
      },
      {
        key: 'notional',
        numeric: true,
        sortable: true,
        sortableColumn: 'nominal_balance',
        title: 'client-manager.table.clients.notional',
        value: row => numberWithSpaces(row.nominal_balance.toFixed(2)),
      },
      {
        key: 'npv',
        numeric: true,
        sortable: true,
        sortableColumn: 'npv',
        title: 'client-manager.table.clients.npv',
        value: row => numberWithSpaces(row.npv.toFixed(2)),
      },
      {
        key: 'npvPercent',
        sortable: true,
        sortableColumn: 'npv_in_percent',
        title: 'client-manager.table.clients.npv-percent',
        value: row => (row.npv_in_percent * 100).toFixed(1) + '%',
      },
      {
        key: 'nextMaturityDate',
        sortable: true,
        sortableColumn: 'next_maturity_date',
        title: 'client-manager.table.clients.next-maturity-date',
        // eslint-disable-next-line
        value: ({ next_maturity_date }) => (
          // eslint-disable-next-line camelcase
          <DateDisplay value={next_maturity_date} isoDateTime />
        ),
      },
      {
        key: 'popupmenu',
        title: '',
        // eslint-disable-next-line
        value: row => (
          <ClientMenu
            client={row}
            handleOpen={() => onClientSelect(row.client_id)}
          />
        ),
        align: 'right',
      },
    ],
  };

  const [orderedList, orderBy, order, onRequestSort] = useSorting(
    clients,
    'nextMaturityDate',
    config.cols,
    false,
  );

  const renderHeader = () => (
    <div>
      <Button size="s" buttonType="outlined">
        client-manager.button.new-client
      </Button>
    </div>
  );

  function handleRowClick(id, clickedRow, event) {
    const isNoninteractivePart =
      event.target.nodeName !== 'TR' &&
      event.target.nodeName !== 'TD' &&
      event.target.nodeName !== 'SPAN';
    if (isNoninteractivePart) return;
    onClientSelect(id);
  }

  return (
    <ContentBox>
      <ActionBar
        title={
          clients ? (
            <FormattedMessage
              id="client-manager.table.clients.title-nr"
              values={{ amount: clients.length }}
            />
          ) : (
            <FormattedMessage id="client-manager.table.clients.title" />
          )
        }
      >
        {actions}
      </ActionBar>
      <Table
        showPlaceholder
        config={config}
        data={orderedList}
        tableHeader={renderHeader()}
        onRowClick={handleRowClick}
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
        loading={isLoading}
      />
    </ContentBox>
  );
}

export default ClientList;
