import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

PercentDisplay.propTypes = {
  hideSuffix: PropTypes.bool,
  value: PropTypes.number,
  decimalScale: PropTypes.number,
  className: PropTypes.string,
};

PercentDisplay.defaultProps = {
  decimalScale: 4,
};

export default function PercentDisplay({
  hideSuffix,
  value,
  decimalScale,
  className,
}) {
  return (
    <NumberFormat
      value={value ? value * 100 : value}
      displayType="text"
      decimalSeparator="."
      decimalScale={decimalScale}
      fixedDecimalScale
      suffix={hideSuffix ? null : '%'}
      className={className}
    />
  );
}
