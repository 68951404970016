import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './switch.scss';

Switch.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

Switch.defaultProps = {
  className: '',
  value: null,
};

export default function Switch({ name, options, className, value, onChange }) {
  function handleChange(e) {
    onChange(e.target.value);
  }

  return (
    <div className={classNames('switch', className)}>
      {options &&
        options.map(o => (
          <label
            className={classNames('switch__item', {
              'switch__item--active': value === o.value,
            })}
            key={o.value}
          >
            <input
              type="radio"
              name={name}
              value={o.value}
              checked={value === o.value}
              onChange={handleChange}
              style={{ display: 'none' }}
            />
            <FormattedMessage id={o.label} />
          </label>
        ))}
    </div>
  );
}
