import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ContentBox from '../../layout/ContentBox';
import ActionBar from '../../layout/ActionBar';
import { ReactComponent as TrendUp } from '../../images/icons/Trend-Up.svg';
import { ReactComponent as TrendDown } from '../../images/icons/Trend-Down.svg';

import './strategy-metrics.scss';

PortfolioStrategyMetrics.propTypes = {
  title: PropTypes.string,
  current: PropTypes.shape({
    total: PropTypes.shape({
      floating_nominal_balance: PropTypes.number,
      nominal_balance: PropTypes.number,
      coupon: PropTypes.number,
      wal_esma: PropTypes.number,
      wam_esma: PropTypes.number,
    }),
  }),
  strategy: PropTypes.shape({
    total: PropTypes.shape({
      floating_nominal_balance: PropTypes.number,
      nominal_balance: PropTypes.number,
      coupon: PropTypes.number,
      wal_esma: PropTypes.number,
      wam_esma: PropTypes.number,
    }),
  }),
};

PortfolioStrategyMetrics.defaultProps = {
  current: {
    total: {
      floating_nominal_balance: 0,
      nominal_balance: 0,
      coupon: 0,
      wal_esma: 0,
      wam_esma: 0,
    },
  },
  strategy: {
    total: {
      floating_nominal_balance: 0,
      nominal_balance: 0,
      coupon: 0,
      wal_esma: 0,
      wam_esma: 0,
    },
  },
};

export default function PortfolioStrategyMetrics({ current, strategy, title }) {
  return (
    <ContentBox noPaddingBottom reducedTopMargin>
      <ActionBar title={<FormattedMessage id={title} />} />
      <div className="strategy-metrics">
        <Item
          value={Number(strategy.total.floating_nominal_balance * 100).toFixed(
            1,
          )}
          superscript="%"
          delta={
            Number(
              (strategy.total.floating_nominal_balance -
                current.total.floating_nominal_balance) *
                100,
            ).toFixed(1) + '%'
          }
          isTrendUp={
            strategy.total.floating_nominal_balance >
            current.total.floating_nominal_balance
          }
        />
        <Separator />
        <Item
          value={(strategy.total.coupon * 100).toFixed(2)}
          superscript="%"
          delta={
            ((strategy.total.coupon - current.total.coupon) * 100).toFixed(2) +
            '%'
          }
          isTrendUp={strategy.total.coupon > current.total.coupon}
        />
        <Item
          value={strategy.total.wam_esma.toFixed(1)}
          superscript="Y"
          delta={
            (strategy.total.wam_esma - current.total.wam_esma).toFixed(1) + 'Y'
          }
          isTrendUp
        />
        <Separator />
      </div>
    </ContentBox>
  );
}

Item.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  superscript: PropTypes.string,
  delta: PropTypes.string,
  isTrendUp: PropTypes.bool,
};
function Item({ value, superscript, delta, isTrendUp }) {
  return (
    <div className="strategy-metrics__item">
      <span className="strategy-metrics__amount">
        {value}
        {superscript && <sup>{superscript}</sup>}
      </span>
      <span className="strategy-metrics__delta">
        {delta} {isTrendUp ? <TrendUp /> : <TrendDown />}
      </span>
    </div>
  );
}

function Separator() {
  return <div className="strategy-metrics__item" />;
}
