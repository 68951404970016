import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function SideNavItem({ disableActive, title, Icon, to }) {
  return (
    <NavLink
      to={to}
      className={navData =>
        `sidenav__item ${
          navData.isActive && !disableActive
            ? 'sidenav__item--active'
            : undefined
        }`
      }
    >
      {Icon && (
        <div className="sidenav__item__icon">
          <Icon />
        </div>
      )}
      <div className="sidenav__item__title">
        <FormattedMessage id={title} />
      </div>
    </NavLink>
  );
}

SideNavItem.propTypes = {
  Icon: PropTypes.object,
  disableActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default SideNavItem;
