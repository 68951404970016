/* eslint-disable */
export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const DEFAULT_MIN_TRADE_DATE = '1920-01-01';

export const ERRORS_BY_STATUS_CODE = {
  500: 'Something went wrong, please try again.',
  440: 'You have been logged out due to inactivity.'
};

export const SESSION_EXPIRED_ERROR = 440;

export const LOGIN_URL =
  process.env.REACT_APP_EMFORMX_IDENTITY_URL +
  '/oauth2/v2.0/authorize?' +
  'p=b2c_1a_signup_signin&' +
  'client_id=' +
  process.env.REACT_APP_EMFORMX_CLIENT_ID +
  '&' +
  'nonce=defaultNonce&' +
  'redirect_uri=' +
  window.location.protocol +
  '//' +
  window.location.host +
  '%2Foauth_callback&' +
  'scope=openid&' +
  'response_mode=query&' +
  'response_type=id_token&' +
  'prompt=login';

export const MIME_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
