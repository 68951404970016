import React from 'react';
import { withStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import MaterialInput from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = () => ({
  formControl: {
    width: '100%',
    cursor: 'pointer',
  },
  input: {
    fontFamily: 'Vaud Medium',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '600',
    cursor: 'pointer',
  },
  inputBase: {
    cursor: 'pointer',
  },
});

Input.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.bool,
  margin: PropTypes.string,
  required: PropTypes.bool,
};

Input.defaultProps = {
  margin: 'normal',
  error: null,
};

function Input({
  id,
  value,
  onChange,
  name,
  label,
  error,
  classes,
  margin,
  required,
  disabled = false,
  ...rest
}) {
  return (
    <FormControl
      className={classes.formControl}
      error={!!error}
      margin={margin}
      required={required}
      disabled={disabled}
      variant="standard"
    >
      {label && (
        <InputLabel htmlFor={id}>
          <FormattedMessage id={label} />
        </InputLabel>
      )}
      <MaterialInput
        id={id}
        value={value || ''}
        name={name}
        onChange={onChange}
        className={classes.input}
        aria-describedby="input-helper-text"
        {...rest}
      />
    </FormControl>
  );
}

export default withStyles(styles)(Input);
