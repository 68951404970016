import PropTypes from 'prop-types';

const TablePropTypes = {
  config: PropTypes.shape({
    cols: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
          .isRequired,
        key: PropTypes.string,
      }),
    ),
    idField: PropTypes.string,
  }),
};

export default TablePropTypes;
