const colors = {
  DARK: '#002D42',
  DARK_25: '#BFCACF',

  DARK_BLUE: '#005B84',
  DARK_BLUE_2: '#102B51',
  DARK_BLUE_25: '#BFD6E0',
  BLUE: '#009EE3',
  LIGHT_BLUE: '#7FCEF1',

  WHITE: '#FFF',
  BLACK: '#000',
  BODY_LIGHT: '#8795A8',

  BACKGROUND_GREY: '#F3F4F6',
  BACKGROUND_GREY_DARK: '#E7E9ED',

  LIGHT_GRAY: '#7F96A0',
  LIGHT_GRAY_2: '#8795A8',
  LIGHT_GRAY_3: '#D8D8D8',
  LIGHT_GREY_4: '#A5A5A5',

  RED: '#DA0000',

  GREEN: '#00AC1C',

  ORANGE: '#FF6E00',

  YELLOW: '#FFC000',
};

export default colors;
