import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ContentBox from '../../layout/ContentBox';
import './release-notes.scss';
import { I18nContext } from '../../I18nContext';
import { getReleaseNotes } from './notes/release-notes-utils';
import PageHeader from '../../components/page-header';

export const ReleaseNotesPage = () => {
  const { locale } = useContext(I18nContext);
  const releaseNotes = getReleaseNotes(locale);
  return (
    <div>
      <PageHeader
        breadcrumbs={[
          {
            title: <FormattedMessage id="menu.page.release-notes" />,
            path: '/release-notes',
          },
        ]}
      />
      <ContentBox>
        {releaseNotes.map((info, index) => (
          <Release key={`i${index}`} releaseInfo={info} />
        ))}
      </ContentBox>
    </div>
  );
};

const Release = ({ releaseInfo }) => {
  const { date, sections, title } = releaseInfo;
  return (
    <div className="release">
      <h3>{title}</h3>
      <div>{date}</div>
      <div className="spacing" />
      {sections &&
        sections.map((data, index) => (
          <SectionElement key={`d${index}`} data={data} />
        ))}
    </div>
  );
};

Release.propTypes = {
  releaseInfo: PropTypes.object.isRequired,
};

const SectionElement = ({ data }) => {
  if (data.type === 'text') {
    return <p>{data.text}</p>;
  }
  if (data.type === 'heading') {
    return <h3>{data.text}</h3>;
  }
  if (data.type === 'list') {
    return (
      <ul>
        {data.items.map((text, index) => (
          <li key={`t${index}`}>{text}</li>
        ))}
      </ul>
    );
  }
  return <p>Unrecognized element!</p>;
};

SectionElement.propTypes = {
  data: PropTypes.object.isRequired,
};
