import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionBar from '../../layout/ActionBar';
import ContentBox from '../../layout/ContentBox';
import colors from '../../colors';

const chartOptions = {
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  tooltip: {
    valueDecimals: 2,
  },
  xAxis: {
    lineColor: 'transparent',
    tickLength: 0,
    labels: {
      style: {
        color: colors.LIGHT_GRAY_2,
      },
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      style: {
        color: colors.LIGHT_GRAY_2,
      },
    },
  },
  legend: { enabled: false },
  plotOptions: {
    bar: { pointWidth: 2 },
  },
};

export default function BasicPointSensitivityChart({ data }) {
  const chartData = {
    xAxis: {
      ...chartOptions.xAxis,
      categories: data.total.map(entry => entry.name).reverse(),
    },
    series: [
      {
        name: '',
        data: data.total.map(entry => entry.value).reverse(),
      },
    ],
  };

  return (
    <ContentBox>
      <ActionBar
        title={
          <>
            <span>
              <FormattedMessage id="chart.basis-point.title" />
            </span>
            {/*
            <IconButton
              aria-label="More"
              aria-owns="some menu"
              aria-haspopup="true"
              onClick={() => {}}
            >
              <MoreHorizIcon />
            </IconButton>
        */}
          </>
        }
        subtitle={<FormattedMessage id="chart.basis-point.subtitle" />}
        spreadWide
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={{ ...chartOptions, ...chartData }}
      />
    </ContentBox>
  );
}

BasicPointSensitivityChart.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.array,
  }),
};

BasicPointSensitivityChart.defaultProps = {
  data: {
    total: [],
  },
};
