import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Arrow } from '../../images/icons/Arrow-Thin.svg';

AdvancedParametersToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
};

function AdvancedParametersToggle({ onClick, enabled }) {
  return (
    <div
      className="advanced-parameters-toggle"
      onClick={onClick}
      role="presentation"
    >
      <button type="button" className="advanced-parameters-toggle__text-button">
        <FormattedMessage
          id={
            enabled
              ? 'common.form.advanced-parameters.hide'
              : 'common.form.advanced-parameters.show'
          }
        />
      </button>
      <div
        className={
          enabled
            ? 'advanced-parameters-toggle__toggle-icon__enabled'
            : 'advanced-parameters-toggle__toggle-icon__disabled'
        }
      >
        <IconButton>
          <Arrow />
        </IconButton>
      </div>
    </div>
  );
}

export default AdvancedParametersToggle;
