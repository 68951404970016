/* eslint-disable no-alert */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../components/Modal';
import Button from '../../../components/button';
import { ReactComponent as WarningIcon } from '../../../images/icons/Warning.svg';
import useStateBoolean from '../../../utils/useStateBoolean';
import api from '../../../utils/api';
import './portfolio-list.scss';

export function ClientMenu({ client, handleOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, openModal, closeModal] = useStateBoolean(false);

  function closeModalAndPopupMenu() {
    closeModal();
    handleClose();
  }

  function handleClick(event) {
    setAnchorEl(event.target);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleDelete() {
    try {
      await api.delete(`/clients?client_id=${client_id}`);
      window.location.reload();
    } catch (e) {
      window.alert('Action failed, try again');
      // eslint-disable-next-line
      console.log('e', e);
    }
  }

  const open = !!anchorEl;

  const { client_id, nr_of_portfolios, nr_of_deals } = client;

  return (
    <>
      <IconButton
        aria-label="More"
        aria-owns="ClientMenu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="client-menu">
          <ul className="client-menu__content">
            <li onClick={handleOpen}>
              <span className="client-menu__item__title" role="presentation">
                <FormattedMessage id="common.button.view" />
              </span>
            </li>
            <li onClick={openModal}>
              <span
                className="client-menu__item__title client-menu__item__title--warning"
                role="presentation"
              >
                <FormattedMessage id="common.button.delete" />
              </span>
            </li>
          </ul>
        </div>
      </Popover>
      <Modal isOpen={isOpen} onClose={closeModalAndPopupMenu}>
        <div className="deleteModal">
          <p className="deleteModal--title">
            <FormattedMessage id="client-manager.delete-popover.title" />
          </p>
          <WarningIcon />
          <p className="deleteModal--query">
            <FormattedMessage
              id="client-manager.delete-popover.question"
              values={{
                details: (
                  <strong>
                    <FormattedMessage
                      id="client-manager.delete-popover.details"
                      values={{
                        client: client_id,
                        nrOfDeals: nr_of_deals,
                        nrOfPortfolios: nr_of_portfolios,
                      }}
                    />
                  </strong>
                ),
              }}
            />
          </p>
          <div className="deleteModal--actions">
            <Button
              buttonType="outlined"
              size="m"
              onClick={closeModalAndPopupMenu}
            >
              common.button.cancel
            </Button>
            <Button buttonType="solid" size="m" onClick={handleDelete}>
              common.button.yes-delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

ClientMenu.propTypes = {
  client: PropTypes.object,
  handleOpen: PropTypes.func.isRequired,
};

export function PortfolioMenu({ portfolio, redirects }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, openModal, closeModal] = useStateBoolean(false);

  function closeModalAndPopupMenu() {
    closeModal();
    handleClose();
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleDelete() {
    try {
      const client_id = /(\/clients\/)+(\S+)/gm.exec(
        window.location.pathname,
      )[2];
      await api.delete(
        `/portfolios?client_id=${client_id}&portfolio_name=${portfolio_name}`,
      );
      if (redirects) {
        window.location.href = window.location.origin + '/clients';
      } else window.location.reload();
    } catch (e) {
      window.alert('Action failed, try again');
      // eslint-disable-next-line
      console.log('e', e);
    }
  }

  const open = !!anchorEl;
  const { portfolio_name, deals } = portfolio;

  return (
    <>
      <IconButton
        aria-label="More"
        aria-owns="PortfolioMenu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="portfolio-menu">
          <ul className="portfolio-menu__content">
            <li onClick={openModal}>
              <span
                className="portfolio-menu__item__title portfolio-menu__item__title--warning"
                role="presentation"
              >
                <FormattedMessage id="common.button.delete" />
              </span>
            </li>
          </ul>
        </div>
      </Popover>
      <Modal isOpen={isOpen} onClose={closeModalAndPopupMenu}>
        <div className="deleteModal">
          <p className="deleteModal--title">
            <FormattedMessage id="client-details.delete-portfolio.title" />
          </p>
          <WarningIcon />
          <p className="deleteModal--query">
            <FormattedMessage
              id="client-details.delete-portfolio.question"
              values={{
                details: (
                  <strong>
                    <FormattedMessage
                      id="client-details.delete-portfolio.details"
                      values={{
                        portfolio: portfolio_name,
                        nrOfDeals: deals.length,
                      }}
                    />
                  </strong>
                ),
              }}
            />
          </p>
          <div className="deleteModal--actions">
            <Button
              buttonType="outlined"
              size="m"
              onClick={closeModalAndPopupMenu}
            >
              common.button.cancel
            </Button>
            <Button buttonType="solid" size="m" onClick={handleDelete}>
              common.button.yes-delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

PortfolioMenu.propTypes = {
  portfolio: PropTypes.object,
  redirects: PropTypes.bool,
};

export function DealMenu({
  deal,
  handleOpen,
  handleAsyncAction,
  lastRowRemaining,
  isDerivative,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, openModal, closeModal] = useStateBoolean(false);

  function closeModalAndPopupMenu() {
    closeModal();
    handleClose();
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleDelete() {
    try {
      await api.delete(`/deals/${id}`);
      if (lastRowRemaining) {
        window.location.href = '/clients';
      } else {
        window.location.reload();
      }
    } catch (e) {
      window.alert('Action failed, try again');
      // eslint-disable-next-line
      console.log('e', e);
    }
  }

  async function handleCopy() {
    try {
      if (handleAsyncAction) {
        handleAsyncAction();
      }
      await api.post(`/copydeals/${id}`);
    } catch (e) {
      window.alert('Action failed, try again');
      // eslint-disable-next-line
      console.log('e', e);
    }
  }

  async function handleHedge() {
    try {
      if (handleAsyncAction) {
        handleAsyncAction();
      }
      await api.post(`/createhedges/${id}`);
    } catch (e) {
      window.alert('Action failed, try again');
      // eslint-disable-next-line
      console.log('e', e);
    }
  }
  const open = !!anchorEl;
  const { deal_name, id } = deal;

  return (
    <>
      <IconButton
        aria-label="More"
        aria-owns="DealMenu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="deal-menu">
          <ul className="deal-menu__content">
            <li onClick={handleOpen}>
              <span className="deal-menu__item__title" role="presentation">
                <FormattedMessage id="common.button.view" />
              </span>
            </li>
            <li onClick={handleCopy}>
              <span className="deal-menu__item__title" role="presentation">
                <FormattedMessage id="common.button.copy" />
              </span>
            </li>
            {isDerivative !== true ? (
              <li onClick={handleHedge}>
                <span className="deal-menu__item__title" role="presentation">
                  <FormattedMessage id="common.button.hedge" />
                </span>
              </li>
            ) : null}
            <li onClick={openModal}>
              <span
                className="deal-menu__item__title--warning"
                role="presentation"
              >
                <FormattedMessage id="common.button.delete" />
              </span>
            </li>
          </ul>
        </div>
      </Popover>
      <Modal isOpen={isOpen} onClose={closeModalAndPopupMenu}>
        <div className="deleteModal">
          <p className="deleteModal--title">
            <FormattedMessage id="client-details.delete-deal.title" />
          </p>
          <WarningIcon />
          <p className="deleteModal--query">
            <FormattedMessage
              id="client-details.delete-deal.question"
              values={{
                details: (
                  <strong>
                    <FormattedMessage
                      id="client-details.delete-deal.details"
                      values={{ deal: deal_name }}
                    />
                  </strong>
                ),
              }}
            />
          </p>
          <div className="deleteModal--actions">
            <Button
              buttonType="outlined"
              size="m"
              onClick={closeModalAndPopupMenu}
            >
              common.button.cancel
            </Button>
            <Button buttonType="solid" size="m" onClick={handleDelete}>
              common.button.yes-delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

DealMenu.propTypes = {
  deal: PropTypes.object.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleAsyncAction: PropTypes.func,
  lastRowRemaining: PropTypes.bool,
  isDerivative: PropTypes.bool,
};
