import '../../../styles/form.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, withFormik } from 'formik';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import {
  DatePicker,
  Select,
  TextField,
  SelectWithCreate,
} from '../../../components/forms/formik/index';
import {
  composeValidators,
  maxLength,
  required,
} from '../../../components/forms/formik/validators';
import {
  CURRENCIES,
  CAP_FLOOR_DIRECTIONS,
  CAP_FLOOR_TYPES,
  PAYMENT_FREQUENCIES,
} from '../utils/lookup-values';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_MIN_TRADE_DATE,
} from '../../../constants';
import FormikMoneyAmountInput from '../../../components/forms/formik/input-types/FormikMoneyAmountInput';
import ActionBar from '../../../layout/ActionBar';
import AdvancedParametersToggle from '../../../components/forms/AdvancedParametersToggle';
import validateOnSubmitOnly from '../../../utils/validateOnSubmitOnly';
import FormControlFooter from '../../../components/forms/FormControlFooter';
import FormikPercentageInput from '../../../components/forms/formik/input-types/FormikPercentageInput';
import { AmortizationInputs } from './components/AmortizationSections';
import { ConventionInputs } from './components/ConventionSections';

const minDate = new Date(DEFAULT_MIN_TRADE_DATE);

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

CapFloorDealForm.propTypes = {
  classes: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCreateClient: PropTypes.func.isRequired,
  onCreatePortfolio: PropTypes.func.isRequired,
  onClientChange: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  instrumentType: PropTypes.string.isRequired,
  portfolios: PropTypes.array,
  footerTitle: PropTypes.string,
  footerDescription: PropTypes.string,
  formErrors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
};

function CapFloorDealForm({
  classes,
  clients,
  instrumentType,
  portfolios,
  onCreateClient,
  onCreatePortfolio,
  onClientChange,
  formErrors,
  setErrors,
  ...formikContext
}) {
  const [advancedDealParamsVisible, setAdvancedDealParams] = useState(false);
  useEffect(() => {
    setErrors(formErrors);
  }, [formErrors, setErrors]);

  const {
    dirty,
    handleReset,
    values,
    setFieldValue,
    submitForm,
    footerTitle,
    footerDescription,
  } = formikContext;

  useEffect(() => {
    if (!portfolios.includes(values.portfolio_name)) {
      setFieldValue('portfolio_name', '');
    }
  }, [portfolios, setFieldValue, values.portfolio_name]);

  const amortizationDisabled =
    values.amortization.amortization_frequency === 'no_amortization';

  const amortizationScheduleAmountsExist =
    values.amortization &&
    values.amortization.amortization_schedule &&
    values.amortization.amortization_schedule.amounts !== null;

  return (
    <Form className={classes.container}>
      {dirty && (
        <FormControlFooter
          title={footerTitle}
          description={footerDescription}
          onCancel={handleReset}
          onSave={submitForm}
        />
      )}
      <div
        className={
          advancedDealParamsVisible ? 'parameters-box-open' : 'parameters-box'
        }
      >
        <ActionBar title={<FormattedMessage id="interest-rate-form.title" />} />
        <Grid container direction="column">
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="loan-deal-name"
                margin="normal"
                name="deal_name"
                label="interest-rate-form.field.deal-name"
                required
                validate={composeValidators(required, maxLength(255))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <SelectWithCreate
                id="loan-portfolio-name"
                label="interest-rate-form.field.portfolio-name"
                name="portfolio_name"
                margin="normal"
                options={portfolios.map(val => ({
                  value: val,
                  text: val,
                }))}
                required
                validate={required}
                createItemPlaceHolder="Enter new portfolio's name"
                onCreateItem={onCreatePortfolio}
                autoOk
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={6} md={6}>
              <SelectWithCreate
                required
                validate={required}
                id="loan-client-id"
                label="interest-rate-form.field.client-id"
                name="client_id"
                margin="normal"
                onChange={event => {
                  const newValue = event.target.value;
                  setFieldValue('client_id', newValue);
                  onClientChange(newValue);
                }}
                options={clients.map(val => ({
                  value: val,
                  text: val,
                }))}
                createItemPlaceHolder="Enter new client's name"
                onCreateItem={onCreateClient}
                autoOk
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="loan-counterparty-name"
                label="interest-rate-form.field.counterparty-name"
                name="counterparty_name"
                margin="normal"
                validate={composeValidators(maxLength(255))}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="loan-nominal-currency"
                label="interest-rate-form.field.nominal"
                name="nominal"
                margin="normal"
                inputComponent={FormikMoneyAmountInput}
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      id="loan-nominal-currency-select"
                      margin="none"
                      name="currency"
                      options={CURRENCIES}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Select
                id="direction"
                margin="normal"
                name="asset_liability"
                label="interest-rate-form.field.direction"
                options={CAP_FLOOR_DIRECTIONS}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Select
                id="cap-floor-type"
                margin="normal"
                name="cap_floor_type"
                label="interest-rate-form.field.cap-type"
                options={CAP_FLOOR_TYPES}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={3} md={3}>
              <Select
                id="payment-frequency"
                margin="normal"
                name="payment_frequency"
                label="interest-rate-form.field.payment-frequency"
                options={PAYMENT_FREQUENCIES}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                margin="normal"
                name="trade_date"
                minDate={minDate}
                label="interest-rate-form.field.trade-date"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                margin="normal"
                name="value_date"
                data-testid="value_date"
                label="interest-rate-form.field.value-date"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                margin="normal"
                name="maturity_date"
                data-testid="maturity_date"
                label="interest-rate-form.field.maturity-date"
                format={DEFAULT_DATE_FORMAT}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="strike"
                label="interest-rate-form.field.strike"
                margin="normal"
                name="strike"
                inputProps={{
                  decimalScale: 4,
                }}
                inputComponent={FormikPercentageInput}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="premium"
                label="interest-rate-form.field.premium"
                margin="normal"
                name="premium"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AdvancedParametersToggle
              onClick={() => {
                setAdvancedDealParams(!advancedDealParamsVisible);
              }}
              enabled={advancedDealParamsVisible}
            />
          </Grid>
        </Grid>
      </div>
      {advancedDealParamsVisible && (
        <div className="advanced-parameters-box">
          {!amortizationScheduleAmountsExist && (
            <Grid container direction="row" spacing={24}>
              <Grid item xs={12} sm={12} md={12}>
                <div className="actions-bar__section_title">
                  <FormattedMessage id="interest-rate-form.section.amort" />
                </div>
                <AmortizationInputs disabled={amortizationDisabled} />
              </Grid>
            </Grid>
          )}
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12} sm={12} md={12}>
              <div className="actions-bar__section_title">
                <FormattedMessage id="interest-rate-form.section.conventions" />
              </div>
              <ConventionInputs />
            </Grid>
          </Grid>
        </div>
      )}
    </Form>
  );
}

function validateForm(values) {
  const errors = {};
  if (!values.deal_name) {
    errors.deal_name = 'Required';
  }

  return errors;
}

function validateOnSubmit(values) {
  const errors = {};
  const maturityDate =
    values.maturity_date instanceof Date
      ? values.maturity_date
      : new Date(values.maturity_date);

  const valueDate =
    values.value_date instanceof Date
      ? values.value_date
      : new Date(values.value_date);

  if (valueDate.getTime() > maturityDate.getTime()) {
    errors.maturity_date = 'Must be after Value date';
  }

  return errors;
}

const encahncedForm = withFormik({
  mapPropsToValues: ({ initialValues }) => initialValues,
  validate: validateForm,
  handleSubmit: (values, { props: { onSubmit }, ...rest }) => {
    validateOnSubmitOnly(onSubmit, validateOnSubmit)(values, rest);
  },
})(CapFloorDealForm);

export const CapFloorForm = withStyles(styles)(encahncedForm);
