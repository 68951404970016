import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';
import colors from '../colors';

const StyledCircularProgress = styled(CircularProgress)`
  color: ${colors.BLUE};
  margin-bottom: 16px;
`;

const StyledLabel = styled('div')`
  color: ${colors.LIGHT_GRAY_2};
  font-size: 14px;
  margin-top: 8px;
`;

LoadingSpinner.propTypes = {
  label: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  label: 'common.loading.default-text',
};

export default function LoadingSpinner({ label }) {
  return (
    <div className="center-both-ways">
      <div>
        <StyledCircularProgress title="Loading content" />
        {label && (
          <StyledLabel>
            <FormattedMessage id={label} />
          </StyledLabel>
        )}
      </div>
    </div>
  );
}
