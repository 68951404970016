import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

ActionBar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  spreadWide: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function ActionBar({ title, subtitle, spreadWide, children }) {
  return (
    <div
      className={classNames('actions-bar', {
        'actions-bar--spreadWide': spreadWide,
      })}
    >
      {title && (
        <div>
          <div className="actions-bar__title">{title}</div>
          {subtitle && <div className="actions-bar__sub-title">{subtitle}</div>}
        </div>
      )}
      {children && <div className="actions-bar__right">{children}</div>}
    </div>
  );
}

export default ActionBar;
